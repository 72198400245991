import BMap from 'BMap'
export const bmapMixin = {//百度地图 混入对象

    data () {
        return {
            bmap_map: null, //百度地图实例
        }
    },
    methods: {
        bmapInit(id) {
            const map =new BMap.Map(id,{enableMapClick:false});//关闭底图可点
            this.bmap_map = map
        }
    }
}