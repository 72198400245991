import AMap from 'AMap'
export const amapMixin = { //百度地图 混入对象

    data() {
        return {
            amap_map: null, //百度地图实例
        }
    },
    methods: {
        amapInit(id) {
            const map = new AMap.Map(id, {
                enableMapClick: false,
            }); //关闭底图可点
            this.amap_map = map
        }
    }
}
