var productCode = [
    {
      code: 'tool_online_wlhy',
      id: null,
      name: '在线调车',
      productMatch: [
        {
          code: 'tool_online_pay',
          name: '在线结算',
        },
        {
          code: 'tool_big_data',
          name: '数据可视化',
        },
        {
          code: 'data_,dingwei,guiji,trace_forSaas',
          name: '北斗坐标',
        },
      ]
    },
    {
      code: 'tool_online_pay',
      name: '在线结算',
      id: null,
      productMatch: [
        {
          code: 'resource_bank',
          name: '银企云托管',
        },
      ]
    },
    {
      code: 'tool_online_tracking',
      name: '在线质控',
      id: null,
      productMatch: [
        {
          code: 'data_,dingwei,guiji,trace_forSaas',
          name: '北斗坐标',
        },
      ]
    },
    {
      code: 'tool_order',
      name: '订单管理',
      id: null,
      productMatch: [
        {
          code: 'tool_contract_price',
          name: '合同智能计费',
        },
        {
          code: 'tool_online_dispatch',
          name: '在线派单',
        },
      ]
    },
    {
      code: 'tool_online_dispatch',
      name: '在线派单',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '在线结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线质控',
        // },
      ]
    },
    {
      code: 'insurance_service_consumer',
      name: '在线投保',
      id: null,
      productMatch: []
    },
    {
      code: 'tool_tms_settle',
      name: '结算管理',
      id: null,
      productMatch: [
        {
          code: 'tool_contract_price',
          name: '合同智能计费',
        },
      ]
    },
    {
      code: 'tool_supplier',
      name: '承运商管理',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '在线结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'tool_less_shipped',
      name: '零担发运',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '在线结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'tool_contract_price',
      name: '合同智能计费',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'tool_blling',
      name: '开票管理',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'tool_owned_car',
      name: '自有车管理',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'tool_bulk_freight',
      name: '大批量发运',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'tool_big_data',
      name: '数据可视化',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'resource_oil_card',
      name: '油卡（实体/电子）',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'resource_insurance',
      name: '货物运输保险',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'resource_etc_ticket',
      name: '通行费票据',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'resource_signature',
      name: '电子签章（E签宝）',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'resource_bank',
      name: '银企云托管',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '在线结算',
        // },
      ]
    },
    {
      code: 'resource_dri_levy',
      name: '司机代征',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'data_,dingwei,guiji,trace_forSaas',
      name: '北斗坐标',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'data_,send_sms',
      name: '短信服务（天翼云）',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'insurance_service_provider',
      name: '保险服务商入驻',
      id: null,
      productMatch: []
    },
    {
      code: 'data_online_shunt',
      name: '车辆资源',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'serve_creation',
      name: '共创服务',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'serve_online_help',
      name: '网络货运申报协助',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'serve_online_retest',
      name: '网络货运等保复测',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'serve_online_freight',
      name: '网络货运平台运营咨询',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'serve_ICP',
      name: 'ICP电信增值年审',
      id: null,
      productMatch: [
        // {
        //     code: 'tool_online_pay',
        //     name: '线上结算',
        // },
        // {
        //     code: 'tool_online_tracking',
        //     name: '在线跟踪',
        // },
      ]
    },
    {
      code: 'e-bidding',
      name: '数据招投标',
      id: null,
      productMatch: [
        {
          code: 'tool_order',
          name: '订单管理',
        },
      ]
    },

    {
      code: 'block_chain',
      name: '数据上链',
      id: null,
      productMatch: []
    }
  ]
  
  var helpDocs = [
    {code:"", name:"订阅操作说明" , url: "https://docs.qq.com/slide/DWVpHQ0hXamtQd3pO"},
    {code:"", name:"中台服务" , url: "https://docs.qq.com/doc/DWUlqdGxLZWRrQXh6?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"大批量发运" , url: "https://docs.qq.com/doc/DWW5tdW51YmNkQlRJ?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"订单管理" , url: "https://docs.qq.com/doc/DWXJadWRadXRDVFFw?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"合同智能计费" , url: "https://docs.qq.com/doc/DWXJadWRadXRDVFFw?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"数据可视化" , url: "https://docs.qq.com/doc/DWWVkUmFTS3NWR2NY?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"结算管理" , url: "https://docs.qq.com/doc/DWWdJQkhScGlpWWpQ?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"在线调车" , url: "https://docs.qq.com/doc/DWU9YclJhT1lEemVl?u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"在线结算" , url: "https://docs.qq.com/doc/DWUJaaWROUURKQXJO"},
    {code:"", name:"在线派单" , url: "https://docs.qq.com/doc/DWVRqeXZkQ0hUYkNm?&u=d994929916a849389ce13ecbdbcb70f8"},
    {code:"", name:"在线质控" , url: "https://docs.qq.com/doc/DWWJPVVpWaER6TGty"}
   
 ]

 export {productCode,helpDocs}