import ls from 'local-storage'

//本地存储 以键值对的方式存储到客户端的Local Storage
export default {
  get(key, defaultVal = null) {
    const val = ls(key)
    if (val) {
      return val
    }
    return defaultVal
  },

  set(key, val) {
    return ls(key, val)
  },

  remove(key) {
    return ls.remove(key)
  },

  clear() {
    ls.clear();
  },
  
  clear2() {//清除 key以export_开头之外 的缓存,export_ 开头的是 导出字段缓存，
    //购物车的也不清理  by  zq 22/02/31
    let delKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (!localStorage.key(i).startsWith("export_") && !localStorage.key(i).startsWith("carList_")) {
        delKeys.push(localStorage.key(i))
      }
    }

    for (const key of delKeys) {
      ls.remove(key)
    }
  }
}