import Vue from 'vue'
//通用实体接口
export default {
    /**
     *通用实例列表查询
     * @param {Object} params 
     * params = {
                    start: this.grid_pageSize * (this.grid_pageNo - 1),
                    limit: this.grid_pageSize,
                    cls:'com.gsh56.tower.bs.Addr',
                    fields:["id","name","tel","linkman","shipper.name","organ.name"],
                    sort:'linkman',
                    q: {
                        "linkman__like":"王"
                    }
                }
     */
    pageEntity(params) {
        return Vue.http.post("api/WmsApiService/pageEntity", {q:JSON.stringify(params),"@ProductCode": "wms"});
    },
    /**
     * 通用Map查询
     * @param {Object} params 
     */
    pageMap(params) {
        return Vue.http.post("api/WmsApiService/pageMap", {q:JSON.stringify(params),"@ProductCode": "wms"});
    },
    /**
     * 
     * @param {*} cls   com.gsh56.tower.oms.Order
     * @param {*} cmd  ord/order.list4shipper
     * @param {*} paramMap 
     */
    findList(cls,cmd,paramMap){
        return Vue.http.post("api/WmsApiService/findList", {cls:cls,cmd:cmd,paramMap:JSON.stringify(paramMap),"@ProductCode": "wms"});
    },
    /**
     * 自定义查询
     * @param {*} cls com.gsh56.tower.oms.Order
     * @param {*} cmd  ord/order.list4shipper
     * @param {*} qp   {q:{}}
     */
    pageMapByCmd(cls,cmd,qp){
        return Vue.http.post("api/WmsApiService/findPage", {cls:cls,cmd:cmd,qp:JSON.stringify(qp),"@ProductCode": "wms"});
    },
    /**
     * 获取单个实体
     * @param {String} cls  class com.gsh56.tower.bs.Addr
     * @param {Number} id  主键 1
     * @param {Number} depth dto关联深度 1
     */

    get(cls,id,depth){
        return Vue.http.post("api/WmsApiService/get", {cls:cls,id:id,depth:depth,"@ProductCode": "wms"});
    },
    /**
     * 持久化实体
     * @param {String} cls class  com.gsh56.tower.bs.Addr
     * @param {Object} json  json
     *   const json ={
                //     name:"小王",
                //     linkman:'小王',
                //     mobile:'13909920093',
                //     shipper:{id:659},
                //     organ:{id:820}
                // }
     * 
     * 
     */
    save(cls,json){
        return Vue.http.post("api/WmsApiService/saveWithJson", {cls:cls,json:JSON.stringify(json),"@ProductCode": "wms"});
    },
    /**
     * 修改方法 不覆盖未传入的json参数
     * @param {String} cls class  com.gsh56.tower.bs.Addr
     * @param {Object} json  json
     *   const json ={
                //     name:"小王",
                //     linkman:'小王',
                //     mobile:'13909920093',
                //     shipper:{id:659},
                //     organ:{id:820}
                // }
     * 
     * 
     */
    update(cls,json){
        return Vue.http.post("api/WmsApiService/updateWithJson", {cls:cls,json:JSON.stringify(json),"@ProductCode": "wms"});
    },
    /**
     *  删除实体
     * @param {String} cls class com.gsh56.tower.bs.Addr
     * @param {Array} ids  id 数组 [1,2]
     */
    remove(cls,ids){
        return Vue.http.post("api/WmsApiService/delete", {cls:cls, id:ids,"@ProductCode": "wms"});
    },

    saveWhSo(params) {
        return Vue.http.post("api/WhSoService/saveSo", { so: JSON.stringify(params),"@ProductCode": "wms"});
    },

    /**
     *通用服务调用
     * @param {Object} svc 
     * @param {Object} method 
     * @param {Object} params 
     */
    request(svc,method,params) {
        params["@ProductCode"] = "wms";
        return Vue.http.post("api/"+svc+"/"+method, params);
    },
    /**
     * 
     * @param {*} searchapi 最好与列表查询接口保持一致
     * @param {*} params 参数 
     * @param {*} fileName 文件名
     */
    exportExcel(fileName,searchapi,...params){
        Vue.http.post("api/export/"+searchapi, ...params,{ responseType: 'blob' }).then((response)=>{
            const blob = new Blob([response.data]);
            if('download' in document.createElement('a')){
                const link = document.createElement('a')
                link.download = fileName || 'default.xls'
                link.style.display = 'none'
                link.href =  window.URL.createObjectURL(blob);
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) // 释放URL 对象
                document.body.removeChild(link)
            }else{
                navigator.msSaveBlob(blob, fileName)
            }
        })
    },


    upload(action,params){
        const config ={
            headers: { "Content-Type": "multipart/form-data" },
        }
        return Vue.http.post(action, params,config);
    },
    /**
     *  文件通用上传
     * @param {String} filedata 文件
     * @param {Boolean} thumb  预览图
     * @param {Boolean} waterMark  水印
     */
    fileUpload(filedata,thumb,waterMark){
        return Vue.http.post("api/file/upload", {filedata:filedata, thumb:thumb,waterMark:waterMark});
    }
}