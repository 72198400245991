const _util = {
  addClass(el, cls) {
    if (arguments.length < 2) {
      el.classList.add(cls)
    } else {
      for (let i = 1, len = arguments.length; i < len; i++) {
        el.classList.add(arguments[i])
      }
    }
  },
  removeClass(el, cls) {
    if (arguments.length < 2) {
      el.classList.remove(cls)
    } else {
      for (let i = 1, len = arguments.length; i < len; i++) {
        el.classList.remove(arguments[i])
      }
    }
  },

}
const $dragging = {
  listeners: {},
  $on(event, func) {
    const events = this.listeners[event]
    if (!events) {
      this.listeners[event] = []
    }
    this.listeners[event].push(func)
  },
  $once(event, func) {
    const vm = this

    function on(...args) {
      vm.$off(event, on)
      func.apply(vm, args)
    }
    this.$on(event, on)
  },
  $off(event, func) {
    const events = this.listeners[event]
    if (!func || !events) {
      this.listeners[event] = []
      return
    }
    this.listeners[event] = this.listeners[event].filter(i => i !== func)
  },
  $emit(event, context) {
    const events = this.listeners[event]
    if (events && events.length > 0) {
      events.forEach(func => {
        func(context)
      })
    }
  }
}

export default function (Vue, options) {
  function bind(el, binding, vnode, oldVnode) {
    if (!binding.arg) {
      console.error('缺少参数，drag或drop')
    } else {
      if (['drag', 'drop'].indexOf(binding.arg) <= -1) {
        console.error('参数有误')
      }
    }
    const opt = binding.arg

    if (opt === 'drag') {
      el.setAttribute('draggable', 'true')
      el.setAttribute('title', binding.value.title)
      _util.addClass(el, 'dragDrop-drag')
      el.dataset.bindingData = JSON.stringify(binding.value.data)
      el.addEventListener('dragstart', handleDragStart, false)
    } else {
      _util.addClass(el, 'dragDrop-drop')
      el.addEventListener('dragover', handleDragOver, false)
      el.addEventListener('dragleave', handleDragLeave, false)
      el.dataset.bindingKey = JSON.stringify(binding.value.key)
      el.addEventListener('drop', handleDragDrop, false)
    }
  }

  function update(el, binding, vnode) {
    const opt = binding.arg
    if (opt === 'drag') {
      el.setAttribute('draggable', 'true')
      el.setAttribute('title', binding.value.title)
      _util.addClass(el, 'dragDrop-drag')
      el.dataset.bindingData = JSON.stringify(binding.value.data)
      el.addEventListener('dragstart', handleDragStart, false)
    } else {
      _util.addClass(el, 'dragDrop-drop')
      el.addEventListener('dragover', handleDragOver, false)
      el.addEventListener('dragleave', handleDragLeave, false)
      el.dataset.bindingKey = JSON.stringify(binding.value.key)
      el.addEventListener('drop', handleDragDrop, false)
    }
  }

  function unbind(el, binding, vnode) {
    const opt = binding.arg
    if (opt === 'drag') {
      el.removeEventListener('dragstart', handleDragStart, false)
    } else {
      el.removeEventListener('dragover', handleDragOver, false)
      el.removeEventListener('dragleave', handleDragLeave, false)
      el.removeEventListener('drop', handleDragDrop, false)
    }
    //$dragging.$off('dragend')
  }

  function findParantNode(el, pcls) {
    if (el.classList.contains(pcls)) {
      return el;
    }
    var cut = el;
    var obj = null;
    while (cut.nodeName != "BODY") {
      if (cut.parentNode.className.indexOf(pcls) > -1) {
        obj = cut.parentNode;
        break;
      } else {
        cut = cut.parentNode;
      }
    }
    return obj;
  }

  function handleDragStart(e) {
    e.dataTransfer.setData('Text', e.target.dataset.bindingData)
  }

  function handleDragOver(e) {

    let dropEL = findParantNode(e.target, 'dragDrop-drop')

    dropEL.style.backgroundColor = '#c1bdbd'
    e.preventDefault()

  }

  function handleDragLeave(e) {
    let dropEL = findParantNode(e.target, 'dragDrop-drop')
    dropEL.style.backgroundColor = ''
    e.preventDefault()
  }

  function handleDragDrop(e) {
    let dropEL = findParantNode(e.target, 'dragDrop-drop')
    dropEL.style.backgroundColor = ''
    e.preventDefault();
    var data = e.dataTransfer.getData('Text')
    $dragging.$emit('dragend', {
      'el': e.target,
      'key': dropEL.dataset.bindingKey,
      'data': JSON.parse(data)
    })
  }

  Vue.prototype.$dragging = $dragging
  Vue.directive('dragdrop', {
    bind: bind,
    update: update,
    unbind: unbind
  })

}