import * as types from '../mutation-types'
 
const state = {
    isCollapse: false,
    scrollTop:0
}


const getters = {
    isCollapse: state => state.isCollapse,
    scrollTop: state => state.scrollTop
}


const actions = {
    setCollapse({ commit },isCollapse) {
        commit(types.MENU_SET_COLLAPSE, isCollapse)
    },
    setScrollTop({ commit },scrollTop){
        commit(types.APP_SCROLL_TOP, scrollTop)
    }
}

const mutations = {
    [types.MENU_SET_COLLAPSE](state, isCollapse) {
        state.isCollapse = isCollapse
    },
    [types.APP_SCROLL_TOP](state, scrollTop) {
        state.scrollTop = scrollTop
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }