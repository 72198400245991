import Vue from 'vue'
import aEntityAPI from "@/api/aEntityAPI";
export default {

  findVhcList (params) {
    return Vue.http.post('api/ShipperVhcService/findVhcList', { p: JSON.stringify(params) })
  },
    findDriverList (params) {
        return Vue.http.post('api/ShipperVhcService/findDriverList', { p: JSON.stringify(params) })
    },
  invite(plate,mobile,driverName, projectId){
    return Vue.http.post('api/ShipperVhcService/invite', { 
      plate: plate,
      mobile:mobile,
      driverName:driverName,
      '@projectId': projectId
    })
  },
  listLicStatus(params){    
    return Vue.http.post('api/ShipperVhcService/listLicStatus', { qp: JSON.stringify(params) })
  },
  
  /**
   * 按照订单查找车辆主轨迹 达成交易时间开始 回单上传截止
   * @param {*} tdo 
   * @param {*} vhc 
   * @param {*} begin 
   * @param {*} end 
   */
  getTdoTrace(tdo,vhc,begin,end){
    return Vue.http.post('api/AppLocService/findTdoVhcMainTrace',{tdo:tdo,vhc:vhc,begin:begin,end:end})
  },

  /**
   * 取得尚未到达的坐标点
   * @param {*} tdo 
   * @param {*} begin 
   * @param {*} end 
   */
  findUnArrivedAddrs(tdo,begin,end){
      return Vue.http.post('api/AppLocService/findUnArrivedAddrs',{tdo:tdo,begin:begin,end:end})
  },
  
 /**
  * 首页地图,在途车辆汇总
  * @param {*} params 
  */
  listMapCnt(params_status){
    return Vue.http.post('api/TdOrderListService/listMapCnt',{qp: JSON.stringify(params_status)})
  },

  listMap(params){
    return Vue.http.post('api/TdOrderListService/listMap',{q:JSON.stringify(params)})
  },

    /**
     * 首页地图,在途车辆，在線車輛數據汇总
     * @param {*} params
     */
    listVhcTotal(params_status){
        return Vue.http.post('api/TdOrderListService/listVhcTotal',{qp: JSON.stringify(params_status)})
    },


    listVhcMap(params){
        return Vue.http.post('api/TdOrderListService/listVhcMap',{q:JSON.stringify(params)})
    }

    ,
    /**
     * 首页地图,在途车辆，在線車輛數據汇总
     * @param {*} paramsR
     */
    listVhcTotalAdmin(params_status){
        return Vue.http.post('api/TdOrderListService/listVhcTotalAdmin',{qp: JSON.stringify(params_status)})
    },

    listVhcMapAdmin(params){
        return Vue.http.post('api/TdOrderListService/listVhcMapAdmin',{q:JSON.stringify(params)})
    },
    setFileValid(cls,licFileId,fileId,flag){
      return Vue.http.post("api/VhcService/setFileValid", { cls:cls,licFileId:licFileId,fileId: fileId ,valid:flag});
    },
    licOcr(fileId,type){
      return Vue.http.post("api/OcrService/vhcLicOcr", { fileId: fileId ,type:type});
    },
    ocrQual(fileId){
      return Vue.http.post("api/OcrTemplateService/iocrQualNotSave", { fileId: fileId});
    },

    /**
     * 甲控运输在途可视化
     * @param {*}
     * @returns
     */
    listVhcTotalAdmin4Tower(params){
      return Vue.http.post('api/TdOrderListService/listVhcTotalAdmin4Tower',{qp: JSON.stringify(params)})
    },

    listVhcMapAdmin4Tower(params){
        return Vue.http.post('api/TdOrderListService/listVhcMapAdmin4Tower',{qp:JSON.stringify(params)})
    },

    /**
     ** 获得历史轨迹数据
     */
     getVhcTrace(params){
      return Vue.http.post('api/LocService/syncTrackNoJms', params)
    },

    /**
     ** 获得运单轨迹数据
     * @param {*} tdo 
     * @param {*} vhc 
     * @param {*} begin 
     * @param {*} end 
     */
     getCurrentVhcTrace(params){
      return Vue.http.post('api/VhcLocService/getTdoTrace', params)
    },
    async getAddr(positionList){
      console.log(positionList);
      return Vue.http.post('api/SaasAppVhcLocService/decodeGpsAddress',positionList)
      
    }
      
  
}
