<template >
  <div>
    <div ref="editor" class="editor"></div>
  </div>
</template>
<script>
import E from "wangeditor";
export default {
  name: "editor",
  data() {
    return {
      editor: null,
      currentValue: this.value,
      isHTML:false
    };
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default:''
    }
  },
  methods: {
  },
  watch: {
    value: function(val, oval) {
      this.currentValue = val;
      // this.editor.txt.html(this.currentValue);
      if (val !== this.editor.txt.html()){
        this.editor.txt.html(this.currentValue);
      }
      // this.editor.selection.moveCursor(this.editor.$textElem.elems[0],false);
    }
  },
  mounted() {

    const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E


    class SourceMenu extends BtnMenu {
      constructor(editor) {
        // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
        const $elem = E.$(
          `<div class="w-e-menu" data-title="源码">
              <span>Html</span>
          </div>`
        )
        super($elem, editor)
      }
      // 菜单点击事件
      clickHandler() {
        let _this = this
        let _editor = _this.editor
        _this.isHTML = !_this.isHTML
        let _source = _editor.txt.html()
        if (_this.isHTML) {
          _source = _source.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/ /g, "&nbsp;")
        } else {
          _source = _editor.txt.text().replace(/&lt;/ig, "<").replace(/&gt;/ig, ">").replace(/&nbsp;/ig, " ")
        }
        _editor.txt.html(_source)
        this.tryChangeActive()
      }
      tryChangeActive() {
        if ( this.isHTML) {
          this.active()
        }else {
          this.unActive()
        }
      }
    }

    const menuKey = 'sourceMenuKey' 

    // 注册菜单
    E.registerMenu(menuKey, SourceMenu)

    var editor = new E(this.$refs.editor);
    editor.config.uploadImgShowBase64 = false;
    editor.config.uploadImgServer = process.env.VUE_APP_BACKEND_URL_PROXY + "/file/upload";
    editor.config.uploadImgHeaders = {}; // 自定义 header
    editor.config.uploadFileName = "filedata";
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgMaxLength = 6;
    editor.config.uploadImgTimeout = 3 * 60 * 1000;
    editor.config.pasteFilterStyle = false;
    // 配置菜单
    editor.config.menus = [
      "sourceMenuKey",
      "head", // 标题
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote", // 引用
      //"emoticon", // 表情
      "image", // 插入图片
      //"table", // 表格
      //"video", // 插入视频
      //"code", // 插入代码
      "undo", // 撤销
      "redo", // 重复
      // "fullscreen" // 全屏
    ];

    editor.config.uploadImgHooks = {
      fail: (xhr, editor, result) => {
        // 插入图片失败回调
      },
      success: (xhr, editor, result) => {
        // 图片上传成功回调
      },
      timeout: (xhr, editor) => {
        // 网络超时的回调
      },
      error: (xhr, editor) => {
        // 图片上传错误的回调
      },
      customInsert: (insertImg, result, editor) => {
        // 图片上传成功，插入图片的回调
        const url =
          this.__ftpPath +
          result.data.filePath;
        insertImg(url);
      }
    };
    editor.config.onchange = html => {
      this.currentValue = html;
      this.$emit("change", html); // 将内容同步到父组件中
    };
    editor.config.customAlert = info => {
      this.$message.error(info);
    };
    

    // editor.config.pasteText = true;
    
    // editor.config.pasteTextHandle = function (content) {
    // var html = content;
    // html = html.replace(/<\/?SPANYES[^>]*>/gi,"");//  移除span
    // html = html.replace(/<(\w[^>]*)  lang=([^|>]*)([^>]*)/gi, "<$1$3");// 移除lang属性
    // html = html.replace(/<\\?\?xml[^>]*>/gi, "");//  移除xml和相关描述
    // html = html.replace(/<\/?\w+:[^>]*>/gi, "");// 移除xml命名空间的标签
    // html = html.replace(/&nbsp;/gi, "");//  移除空格
    // html = html.replace(/^\s+|\s+$/g,"");
    // html = html.replace(/<o:p> <\/o:p>/g,"");//移除标签
    // html = html.replace(/<br\/>/g,"");//移除br标签
    // return html;
    // };
    editor.create();
    this.editor = editor;
    this.editor.txt.html(this.currentValue);
  }
};
</script>
<style>
.editor {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}
.w-e-text-container{
   height: 95%!important;
 }
</style>