import Vue from 'vue';
import ExportColFilterTpl from './exportColFilter.vue'
 
let ExportColFilterConstructor = Vue.extend(ExportColFilterTpl)
let instance;
let seed = 1;
const ExportColFilter = function (options) {
    if (Vue.prototype.$isServer) return;

    options = options || {};
 
    let id = 'export_' + seed++;
    instance = new ExportColFilterConstructor({
        data: options
    });
    instance.id = id;
     
    instance.$mount();
    document.body.appendChild(instance.$el);
 
    return instance;
}

ExportColFilter.close = function(){
    instance.destory()
}
 
export default ExportColFilter;