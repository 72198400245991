<template>
  <div
    :class="['area-picker',areaSize ? 'area-picker--' + areaSize : '', {'is-disabled': areaDisabled}]"
    v-clickoutside="handleClose"
  >
    <div
      tabindex="1"
      class="area-picker-selection"
      @click="toggleOpen"
      @focus="handleFocus"
      @blur="handleBlur"
      ref="pickSelection"
    >
      <span style="color: grey" v-if="!currentValue ">请选择城市</span>
      <template v-if="multi">
        <el-tag
          v-for="item in currentValue"
          :key="item.name"
          :closable="!areaDisabled"
          @close="handleRemove(item)"
          type="info"
          disable-transitions
        >
          <span v-if="level ==1">{{item.province.name}}</span>
          <span v-if="level ==2">{{item.province.name}} / {{item.city?item.city.name:'' }}</span>
          <span v-if="level ==3">
            {{item.province.name}} / {{item.city?item.city.name:'' }} /
            {{item.district?item.district.name:''}}
          </span>
        </el-tag>
      </template>
      <template v-else>
        <el-tag
          :closable="!areaDisabled"
          @close="handleRemove(currentValue)"
          type="info"
          disable-transitions
          v-if="!isEmptyObject(currentValue)"
        >
          <span v-if="level ==1">{{currentValue.province.name}}</span>
          <span
            v-if="level ==2"
          >{{currentValue.province.name}} / {{currentValue.city?currentValue.city.name:'' }}</span>
          <span v-if="level ==3">
            {{currentValue.province.name}} / {{currentValue.city?currentValue.city.name:'' }} /
            {{currentValue.district?currentValue.district.name:''}}
          </span>
        </el-tag>
      </template>
    </div>
    <div class="area-picker-arrow">
      <i class="el-icon-arrow-down" @click="toggleOpen" :class="{'is-reverse': opened}"></i>
    </div>
    <div class="area-picker-dropdown" v-show="opened" :style="dropDownStyle">
      <ul class="picker-nav">
        <li v-if="level>0" :class="{'active':viewName=='Province'}">
          <a @click="showArea('Province')">省份</a>
        </li>
        <li v-if="level>1" :class="{'active':viewName=='City'}">
          <a @click="showArea('City')">城市</a>
        </li>
        <li v-if="level>2" :class="{'active':viewName=='District'}">
          <a @click="showArea('District')">区县</a>
        </li>
      </ul>
      <div class="pick-data-wrap">
        <div class="pick-data-province" v-show="viewName=='Province'">
          <a v-for="(item,index) in data" :key="index" @click="renderCity(index)">
            <template v-if="multi">
              <template v-for="(v,index) in currentValue">
                <template v-if="v.province && item[keyName] == v.province[keyName]">
                  <i class="el-icon-circle-check" :key="index"></i>
                </template>
              </template>
            </template>
            <template v-else>
              <template
                v-if="currentValue && currentValue.province && item[keyName] == currentValue.province[keyName]"
              >
                <i class="el-icon-circle-check" :key="index"></i>
              </template>
            </template>
            {{item.label}}
          </a>
        </div>
        <div class="pick-data-province" v-show="viewName=='City'">
          <a v-for="(item,index) in cityData" :key="index" @click="renderDistrict(index,item)">
            <template v-if="multi">
              <template v-for="(v,index) in currentValue">
                <template v-if="v.city && item[keyName] == v.city[keyName]">
                  <i class="el-icon-circle-check" :key="index"></i>
                </template>
              </template>
            </template>
            <template v-else>
              <template
                v-if="currentValue && currentValue.city && item[keyName] == currentValue.city[keyName]"
              >
                <i class="el-icon-circle-check" :key="index"></i>
              </template>
            </template>
            {{item.label}}
          </a>
        </div>
        <div class="pick-data-province" v-show="viewName=='District'">
          <a v-for="(item,index) in districtData" :key="index" @click="pickDistrict(item)">
            <template v-if="multi">
              <template v-for="(v,index) in currentValue">
                <template v-if="v.district && item[keyName] == v.district[keyName]">
                  <i class="el-icon-circle-check" :key="index"></i>
                </template>
              </template>
            </template>
            <template v-else>
              <template
                v-if="currentValue && currentValue.district && item[keyName] == currentValue.district[keyName]"
              >
                <i class="el-icon-circle-check" :key="index"></i>
              </template>
            </template>
            {{item.label}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from "element-ui/src/mixins/emitter";
import { mapActions } from "vuex";
import { arrayRemove, isEmptyObject } from "../../utils/utils";

import clickoutside from "element-ui/src/utils/clickoutside";
export default {
  name: "GsArea",
  mixins: [emitter],
  directives: {
    clickoutside,
  },
  inject: {
    elForm: {
      default: "",
    },
    elFormItem: {
      default: "",
    },
  },
  props: {
    value: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    keyName: {
      //使用id  还是 value 作为key，影响 默认初始化
      type: String,
      default: "id",
    },
    size: String,
    level: {
      type: Number,
      default: 3, // 1,2,3
    },
    multi: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      //出现位置
      type: String,
      default: "left",
    },
    beforeOpen: {
      type: Function,
    },
  },
  computed: {
    dropDownStyle() {
      let style = {};
      style.top = this.selHeight + "px";
      if (this.placement == "right") style.right = 0;
      return style;
    },
    _elFormItemSize() {
      //继承表单的size
      return (this.elFormItem || {}).elFormItemSize;
    },
    areaSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    areaDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
  data() {
    return {
      opened: false, //
      focused: false,
      data: [],
      currentValue:
        this.value === undefined || this.value === null
          ? this.multi
            ? []
            : this.value
          : this.value,
      selectIdnex: null,
      cityIndex: null,
      viewName: "Province",
      cityData: [],
      districtData: [],
      selHeight: 0,
    };
  },
  methods: {
    ...mapActions(["cache/getWithCache"]),
    handleClose() {
      if (this.opened) {
        this.opened = false;
        this.$emit("on-picker-close");
      }
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit("focus", event);
    },
    handleBlur(event) {
      if (!this.opened) {
        this.focused = false;
        this.$emit("blur", event);
        this.dispatch("ElFormItem", "el.form.blur", this.currentValue);
      }
    },
    toggleOpen() {
      if (this.areaDisabled) {
        return false;
      }
      if (this.beforeOpen && !this.beforeOpen()) {
        return false;
      }
      //计算selection区域的高度以便显示 下拉区域的位置
      this.updateSelectHeight();
      this.opened = !this.opened;
    },
    updateSelectHeight() {
      this.selHeight = this.$refs.pickSelection.offsetHeight;
    },
    showArea(name) {
      if(name=='City'){
        if(this.currentValue && this.currentValue.province){
          this.viewName = name;
        }
      }else if(name=='District'){
        if(this.currentValue && this.currentValue.city){
          this.viewName = name;
        }
      }else{
        this.viewName = name;
      }
    },
    renderProvince(index) {
      this.selectIdnex = index;
      const sp = this.data[this.selectIdnex];
      const isExist = this.checkIsExist(sp);
      if (this.level == 1 && isExist) {
        return;
      } else {
        this.setValue(sp);
      }
      this.handleClose();
    },
    renderCity(index) {
      this.selectIdnex = index;
      const sp = this.data[this.selectIdnex];
      const isExist = this.checkIsExist(sp);
      this.cityData = this.data[this.selectIdnex]["children"];
      if (isExist) {
        return;
      } else {
        this.setValue(sp);
      }
      if (this.level == 1) {
        this.handleClose();
      } else {
        //this.cityData = this.data[this.selectIdnex]['children']
        this.viewName = "City";
      }
    },
    renderDistrict(index, item) {
      if (this.selectIdnex == null) {
        this.$message({
          message: "请先选择省份",
          type: "warning",
        });
        return;
      }
      this.cityIndex = index;
      const sp = this.data[this.selectIdnex];
      const ct = this.cityData[this.cityIndex];
      this.districtData = item.children;
      const isExist = this.checkIsExist(ct);
      if (isExist) {
        return;
      } else {
        this.setValue(sp, ct);
      }
      if (this.level == 2) {
        this.handleClose();
      } else {
        if (this.opened == true) {
          this.viewName = "District";
        }
      }
    },
    pickDistrict(item) {
      if (this.selectIdnex == null) {
        this.$message({
          message: "请先选择省份",
          type: "warning",
        });
        return;
      }
      if (this.cityIndex == null) {
        this.$message({
          message: "请先选择城市",
          type: "warning",
        });
        return;
      }
      const sp = this.data[this.selectIdnex];
      const ct = this.cityData[this.cityIndex];
      const isExist = this.checkIsExist(item);
      if (this.level == 3 && isExist) {
        return;
      } else {
        this.setValue(sp, ct, item);
      }
      this.handleClose();
      this.viewName = "Province";
    },
    setValue(sp, ct, dt) {
      if (this.multi) {
        let s = {};
        if (sp) {
          s.province = {
            id: sp.id,
            value: sp.value,
            name: sp.label,
          };
        }
        if (ct) {
          s.city = {
            id: ct.id,
            value: ct.value,
            name: ct.label,
          };
        }
        if (dt) {
          s.district = {
            id: dt.id,
            value: dt.value,
            name: dt.label,
          };
        }
        this.currentValue.push(s);
      } else {
        let s = {};
        if (sp) {
          s.province = {
            id: sp.id,
            value: sp.value,
            name: sp.label,
          };
        }
        if (ct) {
          s.city = {
            id: ct.id,
            value: ct.value,
            name: ct.label,
          };
        }
        if (dt) {
          s.district = {
            id: dt.id,
            value: dt.value,
            name: dt.label,
          };
        }
        this.currentValue = s;
      }
      this.$emit("input", this.currentValue); //
      this.$emit("change", this.currentValue);
      this.dispatch("ElFormItem", "el.form.change", this.currentValue); //触发所在表单的校验事件
    },
    checkIsExist(item) {
      if (this.value) {
        if (!this.multi) {
          if (
            this.value.province &&
            this.value.province[this.keyName] == item[this.keyName]
          ) {
            return true;
          }
          if (
            this.value.city &&
            this.value.city[this.keyName] == item[this.keyName]
          ) {
            return true;
          }
          if (
            this.value.district &&
            this.value.district[this.keyName] == item[this.keyName]
          ) {
            return true;
          }
        } else {
          for (const d of this.value) {
            if (d.province && d.province[this.keyName] == item[this.keyName]) {
              return true;
            }
            if (d.city && d.city[this.keyName] == item[this.keyName]) {
              return true;
            }
            if (d.district && d.district[this.keyName] == item[this.keyName]) {
              return true;
            }
          }
        }
      }

      return false;
    },
    handleRemove(item) {
      if (this.multi) {
        let inx = null;
        this.currentValue.find((v, index) => {
          if (
            v.province &&
            v.province[this.keyName] == item.province[this.keyName]
          ) {
            inx = index;
          }
          if (v.city && v.city[this.keyName] == item.city[this.keyName]) {
            inx = index;
          }
          if (
            v.district &&
            v.district[this.keyName] == item.district[this.keyName]
          ) {
            inx = index;
          }
        });
        if (inx != null) {
          arrayRemove(this.currentValue, inx, inx);
          this.selectIdnex = null;
          this.cityIndex = null;
          this.viewName = "Province";
        }
      } else {
        this.currentValue = null;
      }
      this.$emit("input", this.currentValue); //
      this.$emit("change", this.currentValue);
      this.dispatch("ElFormItem", "el.form.change", this.currentValue); //触发所在表单的校验事件
    },
    isEmptyObject(obj) {
      return isEmptyObject(obj);
    },
    getData() {
      const cacheParam = {
        ckey: "shengsiqu",
        url: "api/ZbRegionService/getRegionSs",
        param: {
          '@ProductCode':'zbrl'
        },
        cacheType: "localstorage",
      };
      return this["cache/getWithCache"](cacheParam);
    },
    setDefault() {
      if (this.multi) {
        if (this.currentValue.length > 0) {
          for (let i = 0; i < this.currentValue.length; i++) {
            this.data.find((v, index) => {
              if (
                v[this.keyName] == this.currentValue[i].province[this.keyName]
              ) {
                if (this.level == 1) {
                  this.currentValue[i].province.name = v.label;
                  this.renderProvince(index);
                } else if (this.level == 2) {
                  this.currentValue[i].province.name = v.label;
                  const cdata = v.children;
                  cdata.find((c, index) => {
                    if (
                      this.currentValue[i].city &&
                      c[this.keyName] == this.currentValue[i].city[this.keyName]
                    ) {
                      this.currentValue[i].city.name = c.label;
                    }
                  });
                  this.renderCity(index);
                } else {
                  this.currentValue[i].province.name = v.label;
                  this.selectIdnex = index;
                  const cdata = v.children;
                  this.cityData = cdata;
                  if (this.currentValue[i].city) {
                    this.cityData.find((c, index) => {
                      if (
                        this.currentValue[i].city &&
                        c[this.keyName] ==
                          this.currentValue[i].city[this.keyName]
                      ) {
                        this.currentValue[i].city.name = c.label;
                        this.renderDistrict(index, c);
                        const ddata = c.children;
                        if (this.currentValue[i].district) {
                          ddata.find((d, index) => {
                            if (
                              this.currentValue[i].district &&
                              d[this.keyName] ==
                                this.currentValue[i].district[this.keyName]
                            ) {
                              this.currentValue[i].district.name = d.label;
                            }
                          });
                        }
                      }
                    });
                  }
                }
              }
            });
          }
        }
      } else {
        this.data.find((v, index) => {
          if (this.currentValue && this.currentValue.province) {
            if (v[this.keyName] == this.currentValue.province[this.keyName]) {
              if (this.level == 1) {
                this.currentValue.province.name = v.label;
                this.renderProvince(index);
              } else if (this.level == 2) {
                this.currentValue.province.name = v.label;
                const cdata = v.children;
                cdata.find((c, index) => {
                  console.log(this);
                  console.log(this.currentValue);
                  if (
                    this.currentValue.city &&
                    c[this.keyName] == this.currentValue.city[this.keyName]
                  ) {
                    this.currentValue.city.name = c.label;
                  }
                });
                this.renderCity(index);
              } else {
                this.currentValue.province.name = v.label;
                this.$set(
                  this.currentValue.province,
                  this.keyName,
                  v[this.keyName]
                );
                this.selectIdnex = index;
                const cdata = v.children;
                this.cityData = cdata;
                if (this.currentValue.city) {
                  this.cityData.find((c, index) => {
                    if (
                      this.currentValue.city &&
                      c[this.keyName] == this.currentValue.city[this.keyName]
                    ) {
                      this.currentValue.city.name = c.label;
                      this.$set(
                        this.currentValue.city,
                        this.keyName,
                        c[this.keyName]
                      );
                      this.renderDistrict(index, c);
                      const ddata = c.children;
                      if (this.currentValue.district) {
                        ddata.find((d, index) => {
                          if (
                            this.currentValue.district &&
                            d[this.keyName] ==
                              this.currentValue.district[this.keyName]
                          ) {
                            this.currentValue.district.name = d.label;
                            this.$set(
                              this.currentValue.district,
                              this.keyName,
                              d[this.keyName]
                            );
                          }
                        });
                      }
                    }
                  });
                }
              }
            }
          }
        });
      }
    },
  },

  created() {
    this.getData().then((rs) => {
      const data = rs.data;
      this.data = data;
      //有默认值
      this.setDefault();
    });
  },
  destroyed() {},
  watch: {
    value: function (val, oval) {
      this.dispatch("ElFormItem", "el.form.change", val); //触发所在表单的校验事件
      this.currentValue = val;
      this.setDefault();
      this.updateSelectHeight();
    },
  },
};
</script>
<style>
</style>