<template>
    <span :title ="address" class="address-text">
            {{address}}
    </span>
</template>
<script>
    export default {
        computed: {
            address:function(){
                if(this.addressObj.linkman){
                    return  `${this.addressObj.linkman}${this.addressObj.mobile}${this.addressObj.region}${this.addressObj.address}${(this.addressObj.houseNo?this.addressObj.houseNo:"")}` ;
                }else{
                    return '';
                }
                
            }
        },
        props: {
            addressObj: {
                type: Object
            }
        }
    }
</script>