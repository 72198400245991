<template>
  <!-- <transition name="fade"> -->
    <div class="console-menu-search" v-show="show">
      <div class="menu-searh-wrap">
        <el-input placeholder="请输入菜单" v-model="search" class="input-menu-search" @input="searchMenu">

          <el-button slot="prepend" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="menu-search-result" v-show="!noSearchResult && menuSearchResult.length>0">
        <div>找到以下菜单</div>
        <ul class="menu-his-ul">
          <li v-for="item in menuSearchResult" :key="item.id">
            <a @click="searchMenuClick(item)">
              {{item.parentText}} / {{item.text}}
            </a>
          </li>
        </ul>
      </div>
      <div class="menu-search-result" v-show="noSearchResult">
        <div>未找到菜单，请重新搜索</div>
      </div>
      <div class="menu-his-wrap" v-if="hisMenu.length>0">
        <div>最近访问</div>
        <ul class="menu-his-ul">
          <li v-for="item in hisMenu" :key="item.id">
            <router-link :to="item.menu.url">
              {{item.menu.name}}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="menu-his-wrap" v-if="comMenu.length>0">
        <div>常用菜单</div>
        <ul class="menu-his-ul">
          <li v-for="item in comMenu" :key="item.id">
            <router-link :to="item.menu.url">
              {{item.menu.name}}
            </router-link>
          </li>
        </ul>
      </div>

    </div>
  <!-- </transition> -->
</template>

<script>
  import aEntityAPI from "@/api/aEntityAPI.js";

  import {
    filterMenu,
    searchMenuByText


  } from "@/utils/utils";
  export default {

    props: {
      menuData: {
        type: Array
      },
      mxtype: {
        type: String
      },
      show: false
    },

    data() {
      return {
        search: "",
        menuSearchResult: [],
        noSearchResult: false,
        hisMenu: [],
        comMenu: []
      };
    },
    methods: {
      loadHisMenu() {
        aEntityAPI
          .request("UserMenuConfigService", "lastestVisitMenu", null)
          .then(response => {
            if (response.data.success) {
              this.hisMenu = filterMenu(
                response.data.data,
                this.menuData,
                this.mxtype
              );
            }
          });
      },
      loadComMenu() {
        aEntityAPI
          .request("UserMenuConfigService", "listComMenu", null)
          .then(response => {
            if (response.data.success) {
              this.comMenu = filterMenu(
                response.data.data,
                this.menuData,
                this.mxtype
              );
            }
          });
      },
      searchMenu() {
        if (this.search.length > 1) {
          let result = []
          searchMenuByText(this.menuData, this.search, result);
          if (result.length == 0) {
            this.noSearchResult = true
          } else {
            this.noSearchResult = false
          }
          this.menuSearchResult = result;
        } else {
          this.noSearchResult = true
          this.menuSearchResult = []
        }


      },
      searchMenuClick(item) {
        if (item.rootUrl) {
          window.open(item.rootUrl + "#" + item.url, item.text);
        } else {
          if (item.url.endsWith('.html')) {
            window.open(item.url, item.text);
          }
        }

      }



    },
    mounted() {
      this.loadHisMenu();
      this.loadComMenu();
    }
  };
</script>
<style>
  .console-menu-search {
    width: 600px;
    position: fixed;
    height: 100%;
    left: 50px;
    background-color: rgba(51, 51, 51, 0.9);
    top: 50px;
    z-index: 99;
  }

  .console-menu-search div {
    color: #fff;
  }

  .menu-search-result {
    padding: 15px;
  }

  .menu-searh-wrap {
    padding: 15px;
  }

  .input-menu-search {
    width: 300px;
  }

  .input-menu-search input {
    background-color: rgba(51, 51, 51, 0.9);
    border: rgba(51, 51, 51, 0.9);
    color: #fff;
  }

  .input-menu-search button {
    background-color: rgba(51, 51, 51, 0.9);
    border: rgba(51, 51, 51, 0.9);
    color: #fff;
  }

  .input-menu-search .el-input-group__prepend {
    background-color: rgba(51, 51, 51, 0.9);
    border: rgba(51, 51, 51, 0.9);
    color: #fff;
  }

  .menu-his-wrap {
    padding: 15px;
  }

  .menu-his-ul {
    display: flex;
    flex-wrap: wrap;
  }


  .menu-his-ul li {
    padding: 15px;
    float: left;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
    margin-right: 5px;
    position: relative;
    color: #409eff;
  }


  .menu-his-ul li a {
    color: #409eff;
    text-decoration: none;
  }

  .menu-his-ul li a:visited {
    color: #409eff;
  }
</style>