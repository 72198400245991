export const gridMixin = {//数据列表 混入对象
    data () {
        return {
            auto_grid_height:0,
            grid_loading: false, //表格组件table 加载中
            grid_pageNo: 1,//当前页
            grid_total: 0, //总记录数
            grid_pageSize: 20 //每页大小
        }
    }
}