import aes from "../login/aes.js";
import ls from "../utils/ls";

var csrfTokenSeq = -1

function generateRandomString(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}


!(function () {
  XMLHttpRequest.prototype.nativeOpen = XMLHttpRequest.prototype.open;
  var customizeOpen = function (method, url, async = true, user, password) {
    this.nativeOpen(method, url, async, user, password);
    if (url != "https://miao.baidu.com/abdr") {
      //自定义的header会导致 百度地图的请求跨域，排除

      // var reqDomain = config.headers['TenantDomain']
      //     if(reqDomain == null && ls.get("tenantDomain")) {
      //         config.headers['TenantDomain'] = ls.get("tenantDomain");
      //     } else
      //         config.headers['TenantDomain'] =window.location.hostname;
      // }

      //未指定租户库，则手动指定一个
      if (url.indexOf("@TenantDomain") < 0 && url.indexOf("@saasRequest") < 0) {
        var reqDomain = ls.get("tenantDomain");
        if (reqDomain == null) reqDomain = window.location.hostname;
        this.setRequestHeader("tenantDomain", reqDomain);
      }

      // console.log(this);
      // console.log(arguments);

      var routerStart = window.location.href.indexOf(".html#");
      if (routerStart > -1) {
        var refererRouter = window.location.href.substr(routerStart + 6);
        this.setRequestHeader("refererRouter", refererRouter);
      }

      //埋点请求不需要带token
      if (url.indexOf("recordLog") == -1) {
        //csrfToken = csrfRootToken + 请求的序列号
        var token = ls.get("csrfToken");

        if(csrfTokenSeq < 0){    
          //先用ls中值初始化
          var seqStr = ls.get("csrfSeq");
          if (seqStr) {
            csrfTokenSeq = parseInt(seqStr);
          } else {
            ls.set("csrfSeq", csrfTokenSeq);
          }
        } else {
          //全局直接增加
          csrfTokenSeq ++; 
        }
        
        //加密推送
        var tokenJson = {
          key: generateRandomString(50),
          ran: Math.random().toString(20),
          token: token,
          seq: csrfTokenSeq,
        }

        var tokenStr = aes.encrypt(tokenJson)
        this.setRequestHeader("csrfToken", aes.encrypt(JSON.stringify(tokenJson)))

        var dbSeq = parseInt(ls.get("csrfSeq"))
        if(dbSeq > csrfTokenSeq){
          //如果数据库中比较大，取库里的
          csrfTokenSeq = dbSeq
        }else{
          //否则将当前csrfTokenSeq刷新到库里
          ls.set("csrfSeq", csrfTokenSeq);
        }
        
      }
    }
  };

  XMLHttpRequest.prototype.open = customizeOpen;
})();
