import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import msg from './modules/msg'
import page from './modules/page'
import cache from './modules/cache'
import layout from './modules/layout'
import config from './modules/config'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store =  new Vuex.Store({
  modules: {
    user,
    msg,
    page,
    cache,
    layout,
    config
  },
  strict: debug
})


export default store 