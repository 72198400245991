<template>
    <div class="gs-selector-dropdown" v-show="opened">
        <div class="gs-selector-search">
            <el-input v-model="queryValue" @input="queryChange" ref="queryInput" @click="toggleMenu" :size="size"
                :placeholder="searchplaceholder"></el-input>
        </div>


        <el-scrollbar tag="ul" wrap-class="el-select-dropdown__wrap" view-class="gs-selector-dropdown-ul"
            ref="scrollbar" v-loading="loading">
            <li class="gs-selector-dropdown-item" v-for="(item,index) in filterData" :key="index"
                @click.stop="select(item)">
                {{item.name}}
            </li>
            <li class="gs-selector-dropdown-item" v-if="filterData.length == 0 ">{{ nodatatext}}</li>
        </el-scrollbar>
    </div>
</template>
<script>
    export default {
        props: {
            size: String,
            opened: Boolean,
            query: String,
            loading: Boolean,
            toggleMenu: Function,
            select: Function,
            filterData: Array,
            searchplaceholder:String,
            nodatatext:String,
            dropAppendToBody: Boolean
        },
        data() {
            return {
                queryValue: this.query
            }
        },
        methods: {
            queryChange(){
                this.$emit('queryChange', this.queryValue)
            }
        },
        mounted() {
            if (this.opened) {

                if (this.dropAppendToBody) {
                    document.body.appendChild(this.$el);
                }
            }
        },
        watch: {
            opened(val) {
                if (val) {
                    if (this.dropAppendToBody) {
                        document.body.appendChild(this.$el);
                    }
                    //展开自动focus 输入框
                    this.$nextTick(()=>{
                        this.$refs.queryInput.focus();
                    })
                }
            }
        },
        destroyed() {

            if (this.dropAppendToBody && this.$el && this.$el.parentNode) {
                this.$el.parentNode.removeChild(this.$el);
            }
        }

    }
</script>