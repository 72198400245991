<template>
    <span class="contextmenu-wrap" :class="'contextmenuName-' + contextMenuData.menuName">
        <li v-for="item in menulist" :key="item.name" class="menu-list">
            <button @click.stop="handler(item)">
                <span>{{item.name}}</span>
            </button>
        </li>
    </span>
</template>
<script>
    export default {
        props: {
            contextMenuData: {
                type: Object,
                requred: false,
                default () {
                    return {
                        menuName: null,
                        axios: {
                            x: null,
                            y: null
                        },

                    }
                }
            },
            menulist: {
                type: Array,
                default () {
                    return [{
                            handler: 'close-self',
                            name: '关闭当前页'
                        },
                        {
                            handler: 'close-all',
                            name: '关闭所有页面'
                        }, {
                            handler: 'close-oth',
                            name: '关闭其他页面'
                        }
                    ]
                }
            },
            transferIndex: {
                type: Number,
                default: 0
            }
        },
        watch: {
            'contextMenuData.axios' (val) {
                var x = val.x
                var y = val.y
                var _this = this
                var index = _this.transferIndex
                var menuName = 'contextmenuName-' + _this.contextMenuData.menuName
                var menu = document.getElementsByClassName(menuName)[index]
                menu.style.display = 'block'
                menu.style.left = x + 'px'
                menu.style.top = y + 'px'
                document.addEventListener('mouseup', function () {
                    menu.style.display = 'none'
                }, false)
            }
        },
        methods: {
            handler(item) {
                this.$emit(item.handler)
            }
        }
    }
</script>
<style>
    .contextmenu-wrap {
        /* box-shadow: 2px 2px 2px #dedede; */
        display: none;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        border: 1px #dedede solid;
        background: #ffffff
    }

    .contextmenu-wrap .menu-list {
        width: 120px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        background: #ffffff;
        text-decoration: none;
        list-style: none;
    }
    
    .contextmenu-wrap .menu-list button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: block;
        outline: 0;
        border: 0;
        background: #ffffff;
        padding: 2px;
        
    }

    .contextmenu-wrap .menu-list button i,
    .contextmenu-wrap .menu-list button span {
        float: left;
        color: #333;
        padding-left: 10px;
    }

    .contextmenu-wrap .menu-list button i {
        padding: 0 10px 0 10px;
    }

    .contextmenu-wrap .menu-list button:hover {
        background: linear-gradient(to bottom, #ffffff , #e4e4e4);
    }
</style>