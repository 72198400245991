<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" v-loading="uploading">
    <div>
      <slot name="header"></slot>
    </div>
    <div class="gs-uploader-content">
      <el-upload-rw
        drag
        :action="action"
        :data="data"
        name="filedata"
        accept=".xls,.xlsx"
        :show-file-list="false"
        :before-upload="handleBeforeUpload"
        :on-success="handleSuccess"
        v-bind="$attrs"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </el-upload-rw>
    </div>
    <div class="uploader-data">
      <div v-html="returnHtml"></div>
    </div>
    <div slot="footer" class="gs-uploader-footer">
      <el-button v-if="showTemplateDownload" @click="downloadTemplate" style="float: left">下载模板</el-button>
      <a style="display: none" :href="templateUrl" ref="downloadTemplate">下载模板</a>
      <el-button type="primary" @click="handleConfirm">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ElUploadRw from "./el-upload-rw/index.vue";
export default {
  components: {
    ElUploadRw
  },
  computed: {
    returnHtml: function() {
      return this.returnMsg != ""
        ? this.returnMsg.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
        : "";
    }
  },
  props: {
    title: {
      type: String,
      default: "上传"
    },
    templateUrl: {
      type: String
    },
    data: {
      //附带参数
      type: Object,
      default: null
    },
    action: {
      type: String,
      required: true
    },
    templateMethod:{
      type: Function
    },
    showTemplateDownload:{
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      uploading: false,
      returnMsg: ""
    };
  },
  methods: {
    openUploader() {
      this.visible = true;
    },

    handleConfirm() {
      this.returnMsg = "";
      this.visible = false;
    },
    downloadTemplate() {
      if(this.templateMethod == undefined)
        this.$refs.downloadTemplate.click();
      else
        this.templateMethod()
    },
    handleBeforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
          if (!isLt10M) {
              this.$message.error('上传文件大小不能超过 10MB!');
              return false;
          }
      this.uploading = true;
      this.$emit("before-upload", file);
    },
    handleSuccess(response, file, fileList) {
      this.uploading = false;
 
      if (response.success) {
        this.returnMsg = response.data;
        try {
          this.$parent.uploadXlxSuccess()
        }catch (e) {

        }
        
      } else {
        this.returnMsg = response.msg;
        this.$emit("upload-error", response.data);
      }
    }
  }
};
</script>
<style>
.gs-uploader-content .el-upload-dragger {
  width: 460px;
}
</style>