<template>
  <!--<div>-->
  <el-dialog title="导入文件" :visible.sync="tradeDialogVisible" @opened="handleOpenImport" width="90%" 
             @closed="handleDialogClose" ref="import" append-to-body>

    <div class="btns-wrap">
      <div style="float: left">
        <el-button type="primary" @click="cleanImport()">清除提示记录</el-button>
      </div>
    </div>
    <div style="float: right">
      <el-input style="width:150px" placeholder="姓名/手机号" v-model="searchForm.username"
                clearable></el-input>
      <enum-selector v-model="searchForm.taskImportType" clazz="AsyncImportTypeE" class="inline"
                     style="width: 150px;"></enum-selector>
      <el-date-picker
          v-model="searchForm.startAndEndTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          style="width: 220px"
          value-format="yyyy-MM-dd"
          end-placeholder="结束日期">
      </el-date-picker>
      <el-button icon="el-icon-refresh-left" size="mini" @click="reset">重置</el-button>
      <el-button type="primary" size="mini" icon="el-icon-search" @click="search()">搜索</el-button>
    </div>
    <el-table :data="listData" border style="width: 100%" max-height="550"  v-loading="grid_loading">
      <el-table-column type="selection" fixed="left" width="40" align="center"
                       reserve-selection></el-table-column>
      <el-table-column prop="name" label="导入文件" width="200" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.read == 1">{{ scope.row.fileName }}</div>
          <div style="font-weight: bolder;color: #1C1A1C" v-else>{{ scope.row.fileName }}</div>
          <div style="color: #6d737b">{{ scope.row.taskImportType }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="导入进度" width="250" align="center">
        <template slot-scope="scope">
          <div>总 {{ scope.row.totalCount ? scope.row.totalCount : 0 }} 条 /
            导入成功 {{ scope.row.successCount ? scope.row.successCount : 0 }} 条 /
            导入失败 <span :class="{redTxt: scope.row.errorCount !=null && scope.row.errorCount > 0}"> {{ scope.row.errorCount ? scope.row.errorCount : 0 }} </span>条
            <!--            存储条数 <span style="color:#2dc26b;">{{ scope.row.count ? scope.row.count : 0 }}  </span>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag size="medium" type="success"
                  v-if="scope.row.status == '导入完成'">
            {{ scope.row.status }}
          </el-tag>
          <el-tag size="medium" type="warning"
                  v-if="scope.row.status == '导入中'">
            {{ scope.row.status }}
          </el-tag>
          <el-tag size="medium" type="danger"
                  v-if="scope.row.status == '导入结束'">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="执行导入" width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.username ? scope.row.username : scope.row.mobile }}</div>
          <div>{{ scope.row.createTime }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="times" label="耗时" width="80" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.times + "秒" }}
          </div>
        </template>
      </el-table-column>
      
      <el-table-column prop="times" label="备注" width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.remark }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="downloadTime" label="是否阅读" width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.read ==1" type="success">
            已读
          </el-tag>
          <el-tag v-else type="warning">
            未读
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column></el-table-column>
      <el-table-column label="操作" width="120" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="downloadExcel(scope.row)" :disabled="scope.row.fileId==null || scope.row.status != '导入结束'">下载失败记录
          </el-button>
          <!-- <el-button type="text" @click="deleteExcel(scope.row)">取消</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <div style="float: right;">

      <!--      <gh-order-batch ref="ghOrderBatch" class="gh-batch-order-solution-bar" :select-order="selectedRow"-->
      <!--                      @refreshOrd="handleRefreshOrd">-->
      <!--      </gh-order-batch>-->

      <el-pagination background layout="sizes, prev, pager, next, total" :total="grid_total"
                     :page-size.sync="grid_pageSize" @current-change="pageChange"
                     :current-page.sync="grid_pageNo">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
    </div>
  </el-dialog>
  <!--  <console-header ref="consoleHeader" @complete=""></console-header>-->
  <!--</div>-->


</template>
<script>
import {gridMixin} from "@/mixins/grid-mixins";
import aEntityAPI from "@/api/aEntityAPI.js";
import {roleMixin} from "../../mixins/role-mixins.js";
import {_util} from "@/utils/pmss";
import {mapGetters} from "vuex";
import XlxsUploader from "@/components/uploader/xlxs-uploader.vue";
import ls from "@/utils/ls";

// import ConsoleHeader from "@/components/ConsoleHeader";

export default {
  mixins: [gridMixin, roleMixin],
  components: {
    XlxsUploader,
    // ConsoleHeader,
  },
  computed: {
    ...mapGetters({
      shipper: "user/shipper",
      team: "user/team",
    }),
    //按钮：订单竞标
    btnBidRight() {
      if (_util.hasPermission('/bidOrderList', null) == false) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      tradeDialogVisible: false,
      copyIndex: 0,
      unAcceptOrderNum: 0,
      importUrl: process.env.VUE_APP_BACKEND_URL_PROXY + "/api/OmsOrderService/importXls",
      publicPath: process.env.VUE_APP_BASE_URL,
      listData: [], //列表数据
      cls: "OmsOrder",
      isPadding: false,
      dialogFormVisible: false,
      searchForm: {
        //查询表单
        vhc__eq: "",
        createTime__ge: "",
        createTime__le: "",
        type__eq: "",
        source__eq: "",
        startTime: "",
        type: "",
        main: "",
        endTime: "",
        project: {},
        shipper: {},
      },
      selectedRow: [], //已选的行
      assignCarrier: null,
      orderAssignVisible: false,
      orderAssignServer: false,
      sureVisible: false,
      submitting: false,
      OrderBids: {
        type1: null,
        type2: null,
        type3: null,
        issueType: 0,
      },
      rules: {
        type1: [
          {required: true, message: "请选择竞标方式", trigger: "change"},
        ],
        type2: [
          {required: true, message: "请选择报价方式", trigger: "change"},
        ],
      },
      remarkVisible: false,
      batchRemark: ""
    };
  },
  methods: {
    show() {
      this.tradeDialogVisible = true;
    },

    handleOpenImport() {
      this.search(); //打开对话框就查询
    },

    handleDialogClose() {

    },

    cleanImport() {
      aEntityAPI.request("AsyncTaskService", "cleanExport", {
        type: 1
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("导入提示数据清除成功");
          this.search();
          this.$parent.$parent.queryTaskCount();
        } else {
        }
      })
    },

    getCarrierStyle(company_status) {
      if (company_status == '待确认') {
        return 'warning';
      }
      if (company_status == '撤销') {
        return 'danger';
      }
      return 'success';
    },
    // countUnAcceptOrderNum() {
    //   aEntityAPI
    //       .request("OrderAssignService", "countUnAcceptOrder")
    //       .then((response) => {
    //         if (response.data.success) {
    //           this.unAcceptOrderNum = response.data.data;
    //         }
    //       });
    // },
    copyOrder(item) {
      let index = this.copyIndex + 1;
      this.$router.push({
        name: "copyOrder",
        params: {
          copyIndex: index,
          orderId: item.id,
          no: item.no,
          shipperId: item.shipperId,
        },
        query: {
          copyIndex: index,
          orderId: item.id,
          no: item.no,
          shipperId: item.shipperId,
        },
      });
    },
    filterStatus(statusCode) {
      var statusText = "info";
      switch (statusCode) {
        case -1:
          statusText = "danger";
          break;
        case 1:
          statusText = "info";
          break;
        case 4:
          statusText = "";
          break;
        case 7:
          statusText = "";
          break;
        default:
          statusText = "success";
          break;
      }
      return statusText;
    },
    add() {
      this.$router.push({
        name: "newOrder",
        params: {},
        query: {},
      });
    },


    //当前日期向后推迟一天
    nextEndTime(current) {
      if (current == "") return current;
      var dateTime = new Date(current);
      dateTime.setDate(dateTime.getDate() + 1);
      let now = new Date(dateTime);
      return this.formatDate("yyyy-MM-dd hh:mm:ss", now);
    },
    formatResult(item) {
      return item.plate;
    },
    formatSelection(item) {
      //不是必须 默认以name 显示 去报 服务端返回 包含name字段
      return item.plate;
    },
    getParam(start, query) {
      const params = {
        start: start,
        limit: 10,
        cls: "com.gsh56.wlhy.model.bs.Vhc",
        fields: ["id", "plate"],
        q: {
          plate__like: query,
        },
      };

      return {q: JSON.stringify(params)};
    },

    getSearchField() {
      return {
        type: 1,
        username: this.searchForm.username,
        taskImportType: this.searchForm.taskImportType ? this.searchForm.taskImportType.value : null,
        startTime: this.searchForm.startAndEndTime ? this.searchForm.startAndEndTime[0] + " 00:00:00" : null,
        endTime: this.searchForm.startAndEndTime ? this.searchForm.startAndEndTime[1] + " 23:59:59" : null,
      };
    },

    search() {

      let params = {
        start: this.grid_pageSize * (this.grid_pageNo - 1),
        limit: this.grid_pageSize,
        // cls: "AsyncTask",
        // fields: ["id", "name", "count", "status"],
        sort: "id__desc",
        q: this.getSearchField(),
      };
      this.grid_loading = true;
      aEntityAPI.request("AsyncTaskService", "queryAsyncTaskPage", {qp: JSON.stringify(params)}).then((res) => {
        this.grid_loading = false;
        if (res.data.success) {
          this.listData = res.data.data;
          this.grid_total = res.data.total;
        } else {
          this.$message.error(res.data ? res.data.msg : "查询列表失败",)
        }
      }).catch(_ => {
      })
    },

    downloadExcel(row) {

      aEntityAPI.request("AsyncTaskService", "updateAsyncTask", {id: row.id}).then((res) => {
        if (res.data.success) {
          // this.$refs.consoleHeader.queryTaskCount();
          var url = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BACKEND_URL_PROXY + "/file/download?fileGuid=" + row.fileGuid
          
          //saas的用户域名
          var reqDomain = ls.get("tenantDomain")
          if(reqDomain != null){
              url += "&@TenantDomain=" + reqDomain
          }
                
          window.open(url);
          this.$parent.$parent.queryTaskCount();
        }
      });


    },

    download(filePath, fileName, extension) {
      const file = {
        filePath,
        fileName,
        extension,
      };
      aEntityAPI.downloadFilePath(file);
    },

    
    deleteExcel(row) {
      aEntityAPI.request("AEntityApiService", "deleteByTaskId", {id: row.id}).then((res) => {
        if (res.data.success && res.data.data != null) {
          this.$message.success('删除成功');
        }
      });
    },
    pageChange() {
      this.search();
    },
    handleSelect(selection) {
      this.selectedRow = selection;
    },

    validateRowSelected() {
      if (this.selectedRow.length <= 0) {
        this.$message({
          showClose: true,
          message: "请选择数据",
          type: "warning",
        });
        return;
      }
    },

    reset(a) {
      this.searchForm = {
        vhc__eq: "",
        createTime__ge: "",
        createTime__le: "",
        type__eq: "",
        source__eq: "",
        startTime: "",
        type: "",
        main: "",
        endTime: "",
      };
      this.$refs.orderOperateTable.clearSelection();
    },
    moreSearchConfirm() {
      this.dialogFormVisible = false;
      this.search();
    },
    //
    getCarrierParam(start, query) {
      const params = {
        start: start,
        limit: 10,
        cls: "Role",
        fields: ["id", "name", "code"],
        q: {
          name__like: query,
          lineFlag: true,
        },
      };

      return {qp: JSON.stringify(params)};
    },

    accept() {
      this.$router.push({
        name: "orderAccept",
      });
    },
    __refresh() {
      //用于自动刷新
      this.search();
    },
    handleRefreshOrd(flag) {
      if (flag) {
        this.search();
        this.$refs.orderOperateTable.clearSelection();
      }
    },
    batchModifyRemark() {
      this.remarkVisible = true;
    },
  },
  mounted() {
    // this.countUnAcceptOrderNum();
    // this.search();
    // window.location.reload()
  },
  watch: {
    $route(to, from) {
      if (to.name == "orderOperate") {
        // this.countUnAcceptOrderNum();
      }
      // this.$router.go()
    },
  },
};
</script>

<style>
  .redTxt{
      color: red;
  }
</style>

<!--
<style>
  .redTxt{
      color: red;
  }
.carrier-desc {
  color: #f56c6c;
  font-weight: bold;
}

.gh-batch-order-solution-bar {
  display: inline-block;
  width: 550px;
  padding: 2px;
}

.el-dropdown-lrmg {
  margin-left: 10px;
  margin-right: 10px;
}

.el-select {
  width: 100%;
}

.el-input&#45;&#45;mini .el-input__inner {
  padding-left: 4px;
}

.el-input__inner::placeholder {
  color: #dddee1;
}
</style>-->
