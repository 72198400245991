
 import {
    mapGetters
} from 'vuex';
export const roleMixin = {//身份
     
    computed: {
        ...mapGetters({
            __isAuth :"user/authenticated",
            __userInfo: 'user/userinfo',
        }),
        __isShipper(){//企业 发货方
            const towerShipperRole =  this.__isAuth?this.__userInfo.roles.filter(item=>  (item.code == 'tower_admin'||item.code=='admin_supply_chain_tower'||item.code=='saas56_supply_chain_tower')):[];
            return towerShipperRole.length>0?true:false;
        },
        __isShipperWL(){//企业 物流
            const towerShipperRole =  this.__isAuth?this.__userInfo.roles.filter(item=>  item.code == 'tower_wuliu_mng'||item.code=='saas_tower_wuliu_mng'  ):[];
            return towerShipperRole.length>0?true:false;
        },
        __isShipperFN(){//企业 财务
            const towerShipperRole =  this.__isAuth?this.__userInfo.roles.filter(item=> item.code == 'tower_fn_mng'||item.code=='saas_tower_fn_mng' ):[];
            return towerShipperRole.length>0?true:false;
        },
        __isShipperMK(){//企业 市场
            const towerShipperRole =  this.__isAuth?this.__userInfo.roles.filter(item=> item.code == 'tower_market_mng'||item.code=='saas_tower_market_mng' ):[];
            return towerShipperRole.length>0?true:false;
        },
        __isCustomer(){//客户 收货方
            const towerCustrRole =  this.__isAuth?this.__userInfo.roles.filter(item=> item.code == 'tower_cust_admin'||item.code=='tower_customer' ):[];
            return towerCustrRole.length>0?true:false;
        },
        __isCarrier(){//承运商 
            const towerCarrierRole =  this.__isAuth?this.__userInfo.roles.filter(item=> item.code == 'tower_carrier_admin' ):[];
            return towerCarrierRole.length>0?true:false;
        }
    }
   
}