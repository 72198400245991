export default {
    name: 'SelectRenderItem',
    functional: true,
    props: {
        formatItem:Function,
        item: Object,
        width: Number,
        setWidth:Boolean,
        showType: String
    },
    render: (createElement, ctx) => {
        const item = ctx.props.item;
        const fn  = ctx.props.formatItem;
        const width = ctx.props.width-60;
        const showType = ctx.props.showType;

        if(fn){
            if(showType=="multi" && Array.isArray(fn(item))){
                var arr = fn(item)[0].split(',');
                return createElement('li', arr.reverse().map(function (itm,index) {
                    if(index==(arr.length-1)){
                        if(ctx.props.setWidth){
                            return createElement('span',{'style':{'maxWidth':width+'px'}, 'class':'gs-selector-item','attrs':{'title':itm}}, itm)
                        }else{
                            return createElement('span',{'class':'gs-selector-item','attrs':{'title':itm}}, itm)
                        }
                    }else{
                        if(ctx.props.setWidth){
                            return createElement('span',{'style':{'maxWidth':width+'px'}, 'class':'gs-selector-item-right','attrs':{'title':itm}}, itm)
                        }else{
                            return createElement('span',{'class':'gs-selector-item-right','attrs':{'title':itm}}, itm)
                        }
                    }
                }))
            }else{
                if(ctx.props.setWidth){
                    return createElement('span',{'style':{'maxWidth':width+'px'}, 'class':'gs-selector-item','attrs':{'title':fn(item)}}, fn(item))
                }else{
                    return createElement('span',{'class':'gs-selector-item','attrs':{'title':fn(item)}}, fn(item))
                }
            }
        }else{
            if(item.name){
                if(ctx.props.setWidth){
                    return createElement('span',{'style':{'maxWidth':width+'px'},'class':'gs-selector-item','attrs':{'title':item.name}}, item.name)
                }else{
                    return createElement('span',{'class':'gs-selector-item','attrs':{'title':item.name}}, item.name)
                }
            }else{
                console.error("请确保查询结果中包含name字段，或者使用formatResult自定义显示")
            }
        }
    }
};