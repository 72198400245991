<template>
  <el-dialog
    v-if="!destory"
    width="70%"
    title="导出字段筛选"
    :visible.sync="dialogFliterVisible"
    destroy-on-close
  >
    <span>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >全选</el-checkbox>
      <el-checkbox-group v-model="checkColumns" @change="handleCheckedCitiesChange">
        <el-row>
          <el-col :span="4" v-for="item in items" :key="item.dataIndex">
            <el-checkbox :label="item.dataIndex" class="exp-col-header">{{item.header.replace('*','')}}</el-checkbox>
          </el-col>
        </el-row>
      </el-checkbox-group>
    </span>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import ls from "@/utils/ls.js";
const EXPORT_COL_CACHE_MAX = 25;//缓存阈值
const EXPORT_COL_CACHE_KEY = "export_col_cache";//缓存键值
export default {
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      dialogFliterVisible: true,
      checkColumns: [], //已选字段
      items: [], //待选 字段
      key: null, //缓存的key
      onConfirm: null,
      destory: false,
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkColumns = val ? this.items.map(item=>item.dataIndex) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.items.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.items.length;
    },
    cancel() {
      this.dialogFliterVisible = false;
      this.destory = true;
    },
    confirm() {
      if (typeof this.onConfirm === "function") {
        //缓存已选
        if (this.key) {
          let cacheList = [];
          if (ls.get(EXPORT_COL_CACHE_KEY)) {
            cacheList = ls.get(EXPORT_COL_CACHE_KEY);
          }
          if (cacheList.length >= EXPORT_COL_CACHE_MAX) {//超出阈值
            cacheList.splice(0, 1); //首位淘汰
          }
          const cacheMap = {};
          cacheMap[this.key] = this.checkColumns;
          const idx = cacheList.findIndex((item) => item[this.key]);
          if (idx >= 0) {
            cacheList.splice(idx, 1); //删除已有
          }
          cacheList.push(cacheMap);

          ls.set(EXPORT_COL_CACHE_KEY, cacheList);
        }
        const col = this.items.filter(
          (item) => this.checkColumns.indexOf(item.dataIndex) > -1
        );
        this.onConfirm(col);
      }
      this.dialogFliterVisible = false;
      this.destory = true;
    },
  },
  mounted() {
    if (this.key) {
      const cacheList = ls.get(EXPORT_COL_CACHE_KEY);

      if (cacheList && cacheList.length > 0) {
        for (let k = 0; k < cacheList.length; k++) {
          if (cacheList[k].hasOwnProperty(this.key)) {
            for (let i = 0; i < cacheList[k][this.key].length; i++) {
              this.$set(this.checkColumns, i, cacheList[k][this.key][i]);
            }
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.dialogFliterVisible = false;

    this.destory = true;
  },
};
</script>