
import {_util} from '../../utils/pmss'


export default {
    inserted(el, binding, vnode) {
        const { value } = binding
        //const $roles = store.getters && store.getters['user/roles']
        // console.log(value)
        if (value) {
            const url = value.url;
            const permission = value.permission

            var hasPermission =  _util.hasPermission(url, permission)
            var noPermission = !hasPermission;
            if (noPermission === true) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error(`need permission!`)
        }
    }

}
