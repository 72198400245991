import { Message } from 'element-ui';
import userAPI from '../../api/userAPI';
import * as types from '../mutation-types';
import ls from '../../utils/ls';

const state = {
  sysConfig: {},
  orderRecConfig: {}
};

const getters = {
  sysConfig: state => state.sysConfig,
  orderRecConfig: state => state.orderRecConfig
};

const actions = {
  setConfig({
    commit
  }) {
    userAPI.initAppConfig().then((response) => {
      const res = response.data || {};
      if (response.status === 200 && res.success) {
        const resData = res.data || {};
        const { CompanyConfig, ESignConfig, CertConfig } = resData;

        const config = Object.assign({}, CompanyConfig, {esignConfig: (ESignConfig || {})}, {certConfig: (CertConfig || {})});
          
        commit(types.SYS_CONFIG, config);

        // 将相关信息存贮在localstorage
        ls.set('sysConfig', config);
      } else {
        Message({
          showClose: true,
          message: '获取配置信息失败',
          type: 'error'
        });
      }
    });

    userAPI.initOrderRecConfig().then((response) => {
      if (response.status === 200 && response.data.success) {
        commit(types.ORDER_REC_CONFIG, response.data.data);
        // 将相关信息存贮在localstorage
        if (!response.data.data) {
          ls.set('orderRecConfig', {});
        } else {
          ls.set('orderRecConfig', response.data.data);
        }
      } else {
        Message({
          showClose: true,
          message: '获取配置信息失败',
          type: 'error'
        });
      }
    });
  },
  
  refreshConfig({
    commit
  }) {
    userAPI.initAppConfig().then((response) => {
      const res = response.data || {};
      if (response.status === 200 && res.success) {
        const resData = res.data || {};

        const { CompanyConfig, ESignConfig, CertConfig } = resData;
        const config = Object.assign({}, CompanyConfig, {esignConfig: (ESignConfig || {})}, {certConfig: (CertConfig || {})});
        commit(types.SYS_CONFIG, config);
        // 将相关信息存贮在localstorage
        ls.set('sysConfig', config);
        window.location.href = 'common.html#/shipperAuth';
      }
    });

    userAPI.initOrderRecConfig().then((response) => {
      if (response.status === 200 && response.data.success) {
        commit(types.ORDER_REC_CONFIG, response.data.data);
        // 将相关信息存贮在localstorage
        if (!response.data.data) {
          ls.set('orderRecConfig', {});
        } else {
          ls.set('orderRecConfig', response.data.data);
        }
      } else {
        Message({
          showClose: true,
          message: '获取配置信息失败',
          type: 'error'
        });
      }
    });
  },

  checkConfig({
    commit
  }) {
    const sysconfig = ls.get('sysConfig');
    const orderRecConfig = ls.get('orderRecConfig');
    if (sysconfig) {
      commit(types.SYS_CONFIG, sysconfig);
    } else {
      console.log('获取配置信息失败');
      // Message({
      //   showClose: true,
      //   message: '获取配置信息失败',
      //   type: 'error'
      // });
    }

    if (orderRecConfig) {
      commit(types.ORDER_REC_CONFIG, orderRecConfig);
    } else {
      console.log('获取配置信息失败');
      // Message({
      //   showClose: true,
      //   message: '获取配置信息失败',
      //   type: 'error'
      // });
    }
  }

};

const mutations = {
  [types.SYS_CONFIG](state, sysConfig) {
    state.sysConfig = sysConfig;
  },
  [types.ORDER_REC_CONFIG](state, orderRecConfig) {
    state.orderRecConfig = orderRecConfig;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
