import * as types from '../mutation-types'
import ls from '../../utils/ls' 
import Vue from 'vue'
/**
    {
        ckey:缓存所在map的key
        url ：数据请求url
        param ：数据请求参数，
        cacheType ： 缓存类型 ，默认   内存(memory)/localStorage(localstorage)
    }
 */



const state = {
    cacheMap:{
        
    }
}


const getters = {
    
}

const actions = {
    getWithCache({commit,state},cacheParam){
         
        if(cacheParam.cacheType === "memory"){
            if(state.cacheMap[cacheParam.ckey]){
             let promise  = new Promise(function(resolve, reject){
               let rs={isCache:true,data:state.cacheMap[cacheParam.ckey]}
               resolve(rs)
             })
             return promise
            }else{
                return  Vue.http.post(cacheParam.url,cacheParam.param).then((response) =>{
                    const data = response.data
                    if(data.success){
                      const m={};
                      m[cacheParam.ckey] =  data.data
                      commit(types.SET_CACHE,m)
                      return true
                   
                    }else{
                      return false
                    }
                }).then((val)=>{
                  if(val){
                    let promise  = new Promise(function(resolve, reject){
                      let rs={isCache:true,data:state.cacheMap[cacheParam.ckey]}
                      resolve(rs)
                    })
                    return promise;
                  }
                })
        
            }
          }else if(cacheParam.cacheType === 'localstorage'){
            // localstorage 模式下  调整下 存储方式，
            // 校验缓存数据是否支持 id 
            const che = ls.get(cacheParam.ckey); // 获取localStorage
            if(che){//如果存在在localStrorage
              let promise = new Promise(function(resolve, reject){
                let rs  = {isCache:true, data:che, cacheType:cacheParam.cacheType};
                resolve(rs);
              })
              return promise;
            }else{
               return  Vue.http.post(cacheParam.url,cacheParam.param).then((response) =>{
                   const data = response.data
                    if(data.success){
                      ls.set(cacheParam.ckey,data.data)
                      return true
                    }else{
                      return false
                    }
                }).then((val)=>{
                  if(val){
                    let promise  = new Promise(function(resolve, reject){
                      let rs={isCache:true,data:ls.get(cacheParam.ckey)}
                      resolve(rs)
                    })
                    return promise;
                  }
                })
            }
          }
    }
}

const mutations = {
    [types.SET_CACHE](state,cacheM){
      for(const c in cacheM){
        state.cacheMap[c] = cacheM[c]
      }
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}