<template>
  <div class="unset-min-width zq_saas_tab_subscribe">
    <div class="top_view">
      <el-row>
        <el-col :span="6">
          <span style="font-size: 16px; color: #999; margin-left: 20px; margin-right:20px"
            >正在订阅
            <span style="font-size: 20px; color: #000">{{
              curSpName
            }}</span></span
          >
        </el-col>
        <el-col :span="12">
          <div class="title_step">
              <el-button
                  type="text"
                    :class="showTabIndex == 1 ?  'title_step_btn_sel' : 'title_step_btn'"
                    @click="tableClick(1)"
                    v-if="curExp"
              >申请试用</el-button>
              
              <el-divider direction="vertical" v-if="curExp && !onlyExpTag"></el-divider>

              <el-button v-if="!onlyExpTag" type="text" 
                :class="showTabIndex == 0 ?  'title_step_btn_sel' : 'title_step_btn'" 
                @click="tableClick(0)"
                >产品订阅</el-button>


                <!-- <el-button  type="text" @click="getSubscribe()" >刷新</el-button> -->
           
            </div>
        </el-col>
      </el-row>
    </div>

      <template v-if="showTabIndex == 0">
        <sure-subcribe :id="curId" :subId="curSubId"></sure-subcribe>
      </template>
      <template v-if="showTabIndex == 1">
        <saas-exp-subcribe
          @goToSubscribeAction="goToSubscribeAction"
          @changeTabIndexShow="changeTabIndexShow"
          :id="curId"
          :subId="curSubId"
        ></saas-exp-subcribe>
      </template>
  </div>
</template>

<script>
import { gridMixin } from "@/mixins/grid-mixins";
import { mapGetters } from "vuex";
import aEntityAPI from "@/api/sysAPI.js";
import saasExpSubcribe from "./saasExpSubcribe.vue";
import sureSubcribe from "./sureSubcribe.vue";
export default {
  mixins: [gridMixin],
  props: {
    id: {
      type: [String, Number],
    },
    experience: {
      type: [Boolean],
    },
    /**是否测试*/
    newTry: {
      type: Boolean,
      default: false
    },
    spName: {
      type: [String],
    },
  },

  watch:{
    $route(to, from) {

      let that = this;
      if (to.path.indexOf("saasSubscribeTab") > -1) {
        if (to.params.stepActive > 0) {
            that.getSubscribeDetail();
        }
      }
      
    },
  },

  components: { saasExpSubcribe, sureSubcribe },
  computed: {
    ...mapGetters({
      userinfo: "user/userinfo",
      shipper: "user/shipper",
    }),
  },

  data() {
    return {
      tenantDomain: "", //基础Saas管理的tenant的所属domain
      showTabIndex: -2,
      curId: null,
      curSubId:null,
      curCode:null,
      curExp: false,
      curSpName: "",
      onlyExpTag:false,
    };
  },
  methods: {

    tableClick(index) {
      this.showTabIndex = index;
    },


    //跳转到订阅
    goToSubscribeAction(){
      this.showTabIndex = 0;
    },

    changeTabIndexShow(){
      this.onlyExpTag = true;
    },

    getSubscribe(){
      let that = this;
      console.log(that.curId)
      console.log(that.curCode)
      console.log(that.curSubId)

      aEntityAPI.saasRequest("UserProductSubscribeService", "getSaasProductSubscribeByCode", {
          saasProId: that.curId,
          code:that.curCode,
          subId:that.curSubId,
          "@TenantDomain": this.tenantDomain
        })
        .then((res) => {
          console.log('getSubscribe')
          that.subscribeLoding = false;
          if (res.data.success) {
            var dataDict = res.data.data;
            if(dataDict.spName != null){
              that.curSpName = dataDict.spName;
            }

            //试用状态
            if(dataDict.experience && dataDict.billId == null){
              if(dataDict.spsId == null || dataDict.spsStatusValue == 0 || dataDict.spsStatusValue == 5 || dataDict.spsStatusValue == 7){
                that.curExp = true;
                if(dataDict.spsStatusValue == 0 || dataDict.spsStatusValue == 5 || dataDict.spsStatusValue == 7){
                  that.onlyExpTag = true;
                  that.showTabIndex = 1;
                }
              }              
            }
            
            //其余情况，都为订阅
            if(that.showTabIndex <0){
              that.curExp = false;
              that.onlyExpTag = false;
              that.showTabIndex = 0;
            }             
          }
        })
       
  
    }
  },
  // 挂载查询
  beforeMount: function () {
  
    
  },

  mounted() {

    console.log(this.$route)
    if(this.$route.path.indexOf("Try") > -1){
      this.showTabIndex = 1
    } else if (this.$route.params.subId == null){
      //编辑状态
      this.showTabIndex = 0
    }

    if (this.$route.params && this.$route.params.id != null) {
      this.curId = this.$route.params.id;
    }
    if (this.$route.params && this.$route.params.subId != null) {
      this.curSubId = this.$route.params.subId;
    }
    if (this.$route.params && this.$route.params.code != null) {
      this.curCode = this.$route.params.code;
    }
    aEntityAPI
        .saasRequest("MasterTenantService", "getSaasAdminSysTenantDomain", {
          "@TenantDomain": this.tenantDomain
        })
        .then((res) => {
          this.grid_loading = false;
          if (res.data.success) {
            this.tenantDomain = res.data.data.tenantDomain.split(',')[0];
            this.getSubscribe();          
          }
        })
    
  },
  
};
</script>

<style>

.zq_saas_tab_subscribe{
    background-color: #fff;
}

.zq_saas_tab_subscribe .top_view {
  padding-top: 20px;
  border-bottom: 1px solid #999;
  margin-bottom: 40px;
}

.zq_saas_tab_subscribe .title_step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.zq_saas_tab_subscribe .title_step_btn_sel{
    font-size: 20px;
    color: #409EFF;
    border-bottom: 3px solid #409EFF;
    padding-bottom: 10px;
}

.zq_saas_tab_subscribe .title_step_btn{
    font-size: 20px;
    color: #555;
    padding-bottom: 10px;
    border-bottom: 1px solid #409EFF00;
}


</style>
