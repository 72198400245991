<template>
    <div class="grid-wrap" ref="gridWrap" @keyup.enter="handleEnter">
        <div class="grid-header" ref="gridHeader">
            <slot name="header"></slot>
        </div>
        <div class="grid-content" ref="gridContent" :style="bystyle">
            <slot name="content"></slot>
        </div>
        <div class="grid-page" ref="gridPage" :style="ftstyle">
            <slot name="page"></slot>
        </div>
    </div>
</template>
<script>
    import {
        addResizeListener,
        removeResizeListener
    } from 'element-ui/src/utils/resize-event';
    export default {
        data () {
            return {
                 contentHeight:0,
                 contentOffetTop:0,//content 距离视窗顶部的绝对距离
                 viewHeight:0,//视窗高度
            }
        },
        computed: {
            bystyle:function(){

                return {marginBottom:'5px'}
                // if(this.contentHeight+this.contentOffetTop+100 >this.viewHeight){
                //     return {marginBottom:'40px'}
                // }else{
                //     return {marginBottom:'5px'}
                // }
            },
            ftstyle:function(){
                return {position:'static'}
                // if(this.contentHeight+this.contentOffetTop+100 >this.viewHeight){
                //     return {position:'fixed'}
                // }else{
                //     return {position:'static'}
                // }
            }
        },


        methods: {
            contentHeightResize() {
                //this.contentHeight = this.$refs.gridContent.offsetHeight;
                let bottom = 65;
                if(this.$parent.$parent && this.$parent.$parent.$el && this.$parent.$parent.$el.classList.contains("el-tab-pane")){
                    bottom = 80;
                }
                this.viewHeight =  window.innerHeight
                this.contentOffetTop = this.$refs.gridContent.getBoundingClientRect().top
                this.contentHeight = this.viewHeight-this.contentOffetTop-bottom
                this.$parent.auto_grid_height = this.contentHeight
                // //找下属table 组件设置高度
                // for(let i=0;i<this.$children.length;i++){
                //     if(this.$children[i]['tableId']){
                //         this.$children[i].height=this.contentHeight

                //     }
                // }
            },
            handleEnter(){
                if(this.$slots.header && this.$slots.header[0] && this.$slots.header[0].context.search()){
                    this.$slots.header[0].context.search()
                }

            }
        },
        mounted() {
            this.$nextTick(function () { //  DOM 更新之后
                //this.contentHeight = this.$refs.gridContent.offsetHeight
                this.viewHeight =  window.innerHeight
            })
            //  监听content高度变化 相应改变footer的 定位
           addResizeListener(this.$refs.gridContent, this.contentHeightResize);
        },
        beforeDestroy() {
           if (this.$refs.gridContent) removeResizeListener(this.$refs.gridContent, this.contentHeightResize);
        }


    }
</script>
