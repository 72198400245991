<template>
    <i :class="'icon gs-icon ' + name"></i>
</template>

<script>
    export default {
        name: 'GsIcon',
        props: {
            name: String
        }
    };
</script>