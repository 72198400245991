<template>
  <el-dialog
    :visible.sync="dialogVisible"
    append-to-body
    width="400px"
    custom-class="aiDialog"
    :show-close="true"
  >
    <div slot="title" class="dialog-title">
      <img style="width:18px;height:18px;margin-top: 3px" :src="icon" />
      <div class="content" >数字运输调度员</div>
      <div v-loading='dialogLoading' element-loading-background="rgba(0, 0, 0, 0)"></div>
    </div>
    <div class="list_cell">
      <div class="bar" id="top_bar">
        <div v-for="(item, index) in barText" :key="index" class="barItem" @click="selectBar(index)">
          <div class="barItemText" :class="activeIndex == index?'activeText':'unActiveText'">
            <span>{{item.text}}</span>
            <span v-if="item.custNum">{{item.custNum}}</span>
          </div>
          <div class="barItemIcon">
            <img v-show="activeIndex == index" src="../../assets/toolbar/icon_active_bar.png"
              class="barActiveIcon" />
          </div>
        </div>
      </div>
      <div  v-if="!loading" class="scroll-view" :style="'max-height:' + dataListHeight + 'px;'" v-infinite-scroll="loadMore" :infinite-scroll-disabled="infiniteDisabled">
          <div v-for="(item,index) in dataList" :key="item.id" class="listCard">
            <div class="cardTop">
              <span class="cardTop_startAddr">{{analysisAddr(item.tihuo)}}</span>
              <img src="../../assets/toolbar/right.png" class="cardTop_rightIcon" />
              <span class="cardTop_endAddr">{{analysisAddr(item.xiehuo)}}</span>
              <span v-if="item.state == '调车中'" class="status" style="color: #FFA000;background-color: rgba(255,160,0,0.1);" >调度中
              </span>
              <span v-else class="status" style="color: #26C861;background-color: rgba(38,200,97,0.1);">已完成
              </span>
            </div>
            <div class="cardCenter">
              <div class="text-title">
                <span>联系</span>
                <span class="text-value">{{item.linkNum}}</span>
              </div>
              <span class="divider-line" ></span>
              <div class="text-title">
                <span>报价</span>
                <span class="text-value">{{item.tdQuoteNum}}</span>
              </div>
              <span class="divider-line" ></span>
              <div class="text-title">
                <span>最低报价</span>
                <span class="text-value">{{item.tdQuoteMinAmount? (item.tdQuoteMinAmount + "元") : "暂无"}}</span>
              </div>
            </div>
            <div class="cardBottom">
              <div class="cardBottom_left">
                <img src="../../assets/toolbar/goods.png" class="cardBottom_left_left" />
                <span class="cardBottom_text" style="margin-right: 4px;">{{item.goods.name?item.goods.name:""}}</span>
                <span class="cardBottom_text">{{item.goods && item.goods.weight ? item.goods.weight + "吨" : ""}}</span>
              </div>
              <span class="cardBottom_text">{{item.createTime?analysisTime(item.createTime):""}}</span>
            </div>
          </div>
          <div style="height: 1px;"></div>
      </div>
      <div v-show="dataList.length==0">暂无数据</div>
    </div>
    <div class="content" :style="'height:' + dialogHeight + 'px;'">


      <div class="chatView" :style="'height:' + (dialogHeight - 60) + 'px;'">
        
        <div v-for="(item,idx) in chatList" :key="idx" :class="item.isself?'chatself':'chatother'">
					<div v-if="item.time" class="chatTime">{{item.time}}</div>
          <div v-else-if="item.isself" style="display: flex;flex-direction: row;align-items: center;">
            <div v-loading='idx==chatList.length-1 && sendLoading' element-loading-background="rgba(0, 0, 0, 0)"></div>
            <img v-if="idx==chatList.length-1 && sendError" style="width: 20px;height: 20px;margin-right: 5px;" @click="chatSend(1)" src="../../assets/toolbar/icon_error.png" />
            <div class="chatbgvS" >{{item.msg}}</div>
          </div>
          <div v-else style="display: flex;flex-direction: row;">
            <img style="width: 32px;height: 32px;margin-top: 3px" src="../../assets/toolbar/ai.png"/>
            <div style="margin-left: 6px;" >
              <div style="font-size: 13px;color: #9E9E9E;" >数字运输调度员</div>
              <div class="chatbgvO">{{item.msg}}</div>
            </div>
          </div>
        </div>
        <!-- <div style="height: 2rpx;"></div> -->
      </div>

      <div class="chat-view" >
        <el-input class="input-view" style="width:330px" placeholder="有问题尽管问我～" v-model="shuntingContent" @keyup.enter.native="chatSend(0)">
            </el-input>
            <img :src="sendIcon" style="width: 16px;height: 16px;margin-right: 20px;" @click="chatSend(0)" />
      </div>

    </div>
  </el-dialog>
</template>
<script>
import aEntityAPI from "@/api/aEntityAPI";
export default {
  data() {
    return {
      dialogLoading: false,
      sendLoading: false,
      sendError: false,
      infiniteDisabled:false,
      pageNo: 1,
      pageSize: 10,
      dialogVisible: false,
      activeIndex: 0,
      sendIcon: require("@/assets/toolbar/send_light.png"),
      icon: require("@/assets/toolbar/ai_dialog.png"),
      pageHeight: "620",
      dataListHeight: "300",
      dialogHeight: "300",
      shuntingContent: "",
      barText: [{
          "text": "全部调车单"
        },
        {
          "text": "调度中",
          "custNum": 0,
        },
        {
          "text": "已结束",
        },
      ],
      dataList: [],
      chatList: []
    };
  },
  methods: {
    selectBar(selectIndex) {
      this.activeIndex = selectIndex
      this.search();
    },
    open() {
      this.dialogVisible = true;
      this.dataListHeight = this.pageHeight * 0.4;
      this.dialogHeight = this.pageHeight - this.dataListHeight;
      this.searchSchedulingNum();
      this.searchDispatcherChatLog();
      this.search();
    },
    setHeight() {
      this.$nextTick(() => {
        if (this.dataList.length > 0) {
          var element = document.getElementsByClassName("listCard")[0];
          this.dataListHeightTotal = element.scrollHeight * this.dataList.length;
          if (this.dataListHeightTotal <= this.pageHeight * 0.4) {
            this.dataListHeight = this.dataListHeightTotal;
          } else {
            this.dataListHeight = this.pageHeight * 0.4;
          }
        } else {
          this.dataListHeight = 20;
        }
        this.dialogHeight = this.pageHeight - this.dataListHeight
        this.chatScrollTop()
      })
    },

    chatSend(type) {
      let that = this;
      var chatInput;
      if (type == 0) {
        if (!this.shuntingContent) {
          return
        }
        this.chatList.push({
          isself: true,
          msg: this.shuntingContent,
        });
        chatInput = that.shuntingContent;
      } else {
        chatInput = this.chatList.slice(-1)[0].msg;
      }
      this.sendLoading = true;
      this.sendError = false;
      aEntityAPI.request('ShuntingService','chatToDispatcher',{chatInput: chatInput}).then((res) => {
        if (res.data.success) {
          that.sendLoading = false;
          var result = res.data.data;
          that.chatList.push({
            isself: false,
            msg: result.data
          });
          if(result.successPush){
            that.search()
          }
        } else {
          that.sendLoading = false;
          that.sendError = true;
          that.$message.error(res.data.msg);
        }
        that.chatScrollTop()
      });
      that.shuntingContent = ""
      that.chatScrollTop()
    },
    
    chatScrollTop() {
      this.$nextTick(() => {
        var element = document.getElementsByClassName("chatView")[0];
        element.scrollTop = element.scrollHeight;
      })
    },

    analysisAddr(addr){
      let addrList = addr.trim().split(" ");
      if (addrList.length >= 1) {
        return addrList[1]
      }else if(addrList.length >= 0) {
        return addrList[0]
      }else{
        return "暂无"
      }
    },

    analysisTime(time){
      let now = new Date();
      let past = new Date(time);
      // 判断是否有效日期
      if (isNaN(past)) {
        return "";
      }
      
      const diff = now - past;
      const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const diffSeconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (diffDays > 0) {
        return `${diffDays}天前`;
      } else if (diffHours > 0) {
        return `${diffHours}小时前`;
      } else if (diffMinutes > 0) {
        return `${diffMinutes}分钟前`;
      } else {
        return "";
      }
    },

    search(){
      this.pageNo = 1;
      this.loadData();
    },

    loadMore() {
      this.pageNo++;
      this.loadData()
    },

    loadData() {
      let that = this;
      this.infiniteDisabled = true;
      this.dialogLoading = true;
      let qParams = {};
      qParams.limit = 10
      qParams.start = (this.pageNo - 1) * 10;
      let q = {};
      if(this.activeIndex == 0){
        // q.types="1,2";
      }else if(this.activeIndex == 1){
        q.type="1";
      }else if(this.activeIndex == 2){
        q.type="2";
      }
      qParams.q = q;
      aEntityAPI.request('ShuntingService','page',{qp: JSON.stringify(qParams)}).then((res) => {
        if (that.pageNo == 1) {
          that.dataList =  [];
        }
        if (res.data.success) {
          var result = res.data.data;
          // refreshText = "已刷新";
          if (result.length > 0) {
            that.dataList.push(...result);
            that.infiniteDisabled = false;
          } else if (that.pageNo > 1) {
            that.$message.warning('没有更多数据');
          }
        } else {
          that.$message.error(res.data.msg);
        }
        that.setHeight();
        that.dialogLoading = false;
      });
    },

    searchSchedulingNum() {
      let qParams = {};
      let q = {};
      q.type="1";
      qParams.q = q;
      aEntityAPI.request('ShuntingService','searchSchedulingNum',{qp: JSON.stringify(qParams)}).then((res) => {
        if (res.data.success) {
          this.barText[1].custNum = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    searchDispatcherChatLog() {
      let qParams = {};
      let q = {};
      qParams.q = q;
      aEntityAPI.request('ShuntingService','searchDispatcherChatLog',{qp: JSON.stringify(qParams)}).then((res) => {
        if (res.data.success) {
          var result = res.data.data;
          this.chatList = [];
          for (var i = 0; i < result.length; i++) {
            if(i == 0){
              this.chatList.push({
                time:result[i].createTime
              });
            }
            this.chatList.push({
              isself: true,
              msg: result[i].shipperDialog
            })
            this.chatList.push({
              isself: false,
              msg: result[i].botDialog
            })
          }
          this.chatList.push({
            time:"现在"
          });
          this.chatList.push({
            isself: false,
            msg: '你可以输入你想了解的内容～'
          });
          this.chatScrollTop()
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  
  mounted() {
    
  },
  watch: {
    shuntingContent(newValue) {
      if (newValue.trim() === '') {
        this.sendIcon = require("@/assets/toolbar/send_light.png")
      } else {
        this.sendIcon = require("@/assets/toolbar/send.png")
      }
    }
  }
};
</script>
<style lang='scss' scoped>

.barItem {
  margin-right: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.barItemText {
  // height: 75%;
  display: flex;
  align-items: flex-end;
}

.barItemIcon {
  height: 25%;
}

.barActiveIcon {
  width: 22px;
  height: 8px;
}

.unActiveText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.activeText {
  font-size: 18px;
  font-weight: 700;
  color: #1E1F23;
}

.list_cell {
  padding: 18px 18px 8px 18px;
  border-radius: 10px;
  // height: 600px;
  background: linear-gradient(to bottom, #D5F1F6, #FEF4E2);
}

.bar {
  display: flex;
  flex-direction: row;
}

/deep/ .aiDialog {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 10px;
}

/deep/ .aiDialog .el-dialog__body {
  padding: 15px 18px;
  height: 680px;
  background: linear-gradient(to bottom, #EBF1FC, #F5F6F9);
}

/deep/ .aiDialog .el-dialog__header {
  padding: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #2a6bf0;
  padding: 20px 10px;
}

/deep/ .aiDialog {
  .el-dialog__headerbtn {
    background: url("../../assets/toolbar/ai_close.png");
    background-size: cover;
    width: 10px;
    height: 10px;
    top: 28px;
  }
  .el-dialog__headerbtn i {
    font-size: 36px;
    visibility: hidden;
  }
}

/deep/ .dialog-title {
  flex-direction: row;
  display: flex;
  align-items: center;
  //设置图标颜色
  .el-loading-spinner{
    margin-top: -8px;
    margin-left: 2px;
    .path{
      stroke: white;
    }
  }
}

.dialog-title .content{
  font-size: 20px;
  margin-left: 8px;
  font-family: PangMenZhengDao, PangMenZhengDao;
  font-weight: 400;
  color: #FFFFFF;
}

.scroll-view {
  overflow: hidden;
  overflow-y: scroll;
}

.listCard {
  padding: 14px;
  margin-top: 10px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
}

.cardTop {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.cardTop_startAddr {
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  color: #191919;
  line-height: 16px;
}

.cardTop_rightIcon {
  height: 8px;
  width: 21px;
  margin-left: 11px;
  margin-right: 11px;
}

.cardTop_endAddr {
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  color: #191919;
  line-height: 16px;
}

.status {
  height: 24px;
  width: 58px;
  border-radius: 10px 10px 10px 0;
  margin-left: 13px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.cardCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0 0 10px 
}

.divider-line {
  height: 12px;
  margin: 0 20px;
  border: 1px solid #D8D8D8;
}

.text-title {
  font-size: 14px;
  color: #666666;
}

.text-value {
  color: #FFA000;
  font-size: 16px;
  font-weight: 500;
}

.cardBottom {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardBottom_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cardBottom_left_left {
  width: 13px;
  height: 13px;
  margin-right: 4px;
}

.cardBottom_text {
		height: 14px;
		font-size: 13px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 14px;
		max-width:100px;
		
		/* 确保容器宽度是固定的，或者至少有一个最大宽度 */

		/* 防止文本换行 */
		white-space: nowrap;

		/* 隐藏超出容器的文本 */
		overflow: hidden;

		/* 当文本溢出时，显示省略号 */
		text-overflow: ellipsis
}

.content {
  width: 100%;

  .chatself {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 5px 0 38px
  }

  .chatother {
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .chatTime{
    color: #333333;
    width: 100%;
    text-align: center;
  }

  .chatbgvS {
    color: #333333;
    padding: 10px 15px;
    background-color: #B6DDFD;
    font-size: 14px;
    border-radius: 10px 10px 0px 10px;
  }

  .chatbgvO {
    color: #333333;
    padding: 10px 15px;
    background-color: white;
    margin-top: 7px;
    font-size:14px;
    border-radius: 10px 10px 10px 0px
  }

  .chat-view {
    // position: fixed;
    height: 42px; 
    display: flex;
    background: white;
    align-items: center;
    border-radius: 10px;
    align-items: center;
    border-radius: 10px;
    position: absolute;
    bottom: 10px;
    
    /deep/ .input-view .el-input__inner {
      border: 0;
    }
  }
}

.voiceButton{
  /* #ifndef APP-NVUE */
  width: 100%;
  /* #endif */
  flex: 1;
  position: relative;
  text-align: center;
  height: 30px;
  color: #333;
  border: #dcdfe6 0.5px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chatView {
  padding-top: 13px;
  overflow: hidden;
  overflow-y: scroll;
  /* 将滚动条的位置设置为最下方 */
  // scroll-padding-bottom: 10px; 
}

::-webkit-scrollbar {
  display: none;
}

/deep/ .el-loading-spinner{
  margin-top: -12px;
  margin-left: -26px;
  .circular {
    height: 20px;
    width: 20px;
    // -webkit-animation: loading-rotate 2s linear infinite;
    // animation: loading-rotate 2s linear infinite; }
  }
}

</style>