<template>
    <div :class="['gs-selector',enumSize ? 'gs-selector--' + enumSize : '', {'is-disabled': enumDisabled}]"
        v-clickoutside="handleClose">
        <div class="gs-selector-selection" @click="toggleMenu" ref="enumSelection">

            <template v-if="multi">
                <span class="gs-selector-placeholder" v-show="value.length==0 ">
                    {{placeholder}}
                </span>
                <el-tag v-for="(item,index) in currentValue" :key="index" :closable="!enumDisabled"
                    @close="handleRemove(item)" type="info" disable-transitions>
                    {{item.name}}
                </el-tag>
            </template>
            <template v-else>
                <span class="gs-selector-placeholder" v-show="isEmptyObject(currentValue)">
                    {{placeholder}}
                </span>
                <el-tag :closable="!enumDisabled" @close="handleRemove(currentValue)"
                    v-if="!isEmptyObject(currentValue)" type="info" disable-transitions>
                    {{currentValue.name}}
                </el-tag>
            </template>
        </div>
        <div class="gs-selector-arrow">
            <i class="el-icon-arrow-down" @click="toggleMenu" :class="{'is-reverse': opened}"></i>
        </div>
        <transition name="el-zoom-in-top">
            <drop :opened="opened" :searchplaceholder="searchplaceholder" :nodatatext="nodatatext" ref="drop"
                :style="dropDownStyle" :size="size" :drop-append-to-body="dropAppendToBody" :query="query"
                :toggle-menu="toggleMenu" :loading="loading" @queryChange="handleQueryChange"  :select="select" :filter-data="filterData"></drop>
        </transition>
    </div>
</template>
<script>
    import emitter from 'element-ui/src/mixins/emitter';
    import Drop from './drop.vue'
    import {
        mapActions
    } from 'vuex';
    import {
        arrayRemove, isEmptyObject
    } from '../../utils/utils'
    import clickoutside from '../clickoutside';
    export default {
        name: "EnumSelector",
        directives: {
            clickoutside
        },
        components: {
            Drop
        },
        mixins: [emitter],
        inject: {
            elForm: {
                default: ''
            },
            elFormItem: {
                default: ''
            }
        },
        props: {
            value: {
                type: [Array, Object],
                default() {
                    return []
                }
            },
            service:{
                type: String,
                default: 'SysApiService'
            },
            placeholder: {
                type: String,
                default: '请选择'
            },
            searchplaceholder: {
                type: String,
                default: '请输入关键词搜索'
            },
            nodatatext: {
                type: String,
                default: '暂无数据'
            },
            clazz: {
                type: String,
                required: true
            },
            size: String,
            multi: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            dropAppendToBody: {
                type: Boolean,
                default: false
            },
            filterFn:{
                type: Function,
                default: null
            }
        },
        computed: {
            dropDownStyle() {
                let style = {}
                if (!this.dropAppendToBody) {
                    style.top = this.selHeight + 'px';
                } else {
                    style.top = this.absoulteTop + this.selHeight + 'px';
                    style.left = this.absoulteLeft + 'px';
                }
                style.minWidth = this.selWidth + 'px'
                return style;
            },
            _elFormItemSize() { //继承表单的size
                return (this.elFormItem || {}).elFormItemSize;
            },
            enumSize() {
                return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
            },
            enumDisabled() {
                return this.disabled || (this.elForm || {}).disabled;
            },
            filterData() {
                var self = this
                return self.data.filter(item => item.name.indexOf(self.query) !== -1)
            }
        },
        data() {
            return {
                query: '',
                opened: false, //
                data: [],
                currentValue: this.value === undefined || this.value === null ? this.multi ? [] : this.value : this.value,
                selHeight: 0,
                selWidth: 0,
                loading: false,
                absoulteTop: 0,
                absoulteLeft: 0
            }
        },
        methods: {
            ...mapActions([
                "cache/getWithCache",
            ]),
            handleClose() {
                this.opened = false;
                this.$emit('on-selector-close');
            },
            toggleMenu() {
                if (this.enumDisabled) {
                    return false
                }
                this.updateSelectHeight();
                this.opened = !this.opened
            },
            updateSelectHeight() {
                this.selWidth = this.$refs.enumSelection.offsetWidth
                this.selHeight = this.$refs.enumSelection.offsetHeight
                this.absoulteTop = this.$refs.enumSelection.getBoundingClientRect().top
                this.absoulteLeft = this.$refs.enumSelection.getBoundingClientRect().left
            },
            handleRemove(item) {
                if (this.enumDisabled) {
                    return false
                }
                if (this.multi) {
                    let inx = null;
                    this.currentValue.find((v, index) => {
                        if (v['value'] == item['value']) {
                            inx = index;
                        }
                    })
                    if (inx != null) {
                        arrayRemove(this.currentValue, inx, inx)
                    }
                } else {
                    this.currentValue = null;
                }
                this.$emit('input', this.currentValue);
                this.$emit('change', this.currentValue);
            },
            isEmptyObject(obj) {
                return isEmptyObject(obj)
            },
            getData() {
                //WlhyApiService
                const cacheParam = {
                    ckey: this.clazz,
                    url: 'api/'+this.service+'/findEnum',
                    param: {
                        'cls': this.clazz
                    },
                    cacheType: 'memory'
                }
                return this["cache/getWithCache"](cacheParam)
                
            },
            checkIsExist(item) {
                if (!this.multi) {
                    return false;
                }
                for (const d of this.value) {
                    if (d['value'] == item['value']) {
                        return true
                    }
                }
                return false;
            },
            select(item) {
                const exist = this.checkIsExist(item);
                if (exist) {
                    return
                }

                if (!this.multi) {
                    this.currentValue = item
                    this.opened = false
                } else {
                    this.currentValue.push(item)
                }
                this.$emit('input', this.currentValue);
                this.$emit('change', this.currentValue);
            },
            setDefault() {
                if (this.multi) {
                    if (this.currentValue && this.currentValue.length > 0) {
                        for (let i = 0; i < this.currentValue.length; i++) {
                            this.data.find((v, index) => {
                                if (this.currentValue[i]["value"] != null) {
                                    if (this.currentValue[i]["value"] == v["value"]) {
                                        this.currentValue[i] = v
                                    }
                                } else if (this.currentValue[i]["name"]) {
                                    if (this.currentValue[i]["name"] == v["name"]) {
                                        this.$set(this.currentValue[i], "value", v["value"])
                                    }
                                }
                            })
                        }
                    }

                } else {
                    this.data.find((v, index) => {
                        if (this.currentValue) {
                            if (this.currentValue["value"] != null) {
                                if (this.currentValue["value"] == v["value"]) {
                                    this.$set(this.currentValue, "name", v["name"])
                                }
                            } else if (this.currentValue["name"]) {
                                if (this.currentValue["name"] == v["name"]) {
                                    this.$set(this.currentValue, "value", v["value"])
                                }
                            }
                        }

                    })
                }

            },
            handleQueryChange(val){
                this.query = val
            }
        },
        created() {
            this.loading = true
            this.getData().then((rs) => {
                const data = rs.data;
                console.log(this.filterFn)
                 if(this.filterFn && typeof(this.filterFn) =='function'){
                    this.data = rs.data.filter(item => this.filterFn(item))
                }else{
                    this.data = data;
                }
                
                this.loading = false;
                //处理默认值
                this.setDefault()
            })
        },
        watch: {
            value: function (val, oval) {
                this.dispatch('ElFormItem', 'el.form.change', val); //触发所在表单的校验事件
                this.currentValue = val
                this.setDefault()
                this.$nextTick(() => {
                    this.updateSelectHeight();
                });

            }
        }
    }
</script>