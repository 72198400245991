<template>
  <div class="unset-min-width zq_saas_subscribe">
    <div class="body_view">
      <el-form :model="subscribeDto" label-width="100px" ref="dataDictRef">
        <el-row type="flex" justify="center" style="margin-bottom: 20px">
          <el-col :span="14">
            <el-steps :active="stepActive" finish-status="success" align-center>
              <el-step title="提交申请"></el-step>
              <el-step title="平台审核"></el-step>
              <el-step title="安装部署"></el-step>
              <el-step title="开始试用"></el-step>
            </el-steps>
          </el-col>
        </el-row>

        <el-row>
          <el-col :offset="4" :span="16">
            <box class="box_view_top" title="用户信息">
              <template v-slot:body>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      label="用户类型"
                      prop="tenantInfo.type"
                      :rules="{
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                      }"
                    >
                      <enum-selector
                        :disabled="stepActive != 0"
                        v-model="subscribeDto.tenantInfo.type"
                        placeholder="请选择"
                        clazz="TenantInfoTypeE"
                      ></enum-selector>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      label="用户名称"
                      prop="tenantInfo.name"
                      :rules="{
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                      }"
                    >
                      <el-input
                        :disabled="stepActive != 0"
                        v-model="subscribeDto.tenantInfo.name"
                        placeholder="个人/企业名称"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      label="系统域名"
                      prop="tenantInfo.domain"
                      :rules="{
                        required: false,
                        message: '不能为空',
                        trigger: 'change',
                      }"
                    >
                      <el-input
                        :disabled="stepActive != 0"
                        v-model="subscribeDto.tenantInfo.domain"
                        placeholder="可不填，由系统分配"
                      >
                      <template slot="prepend">https://</template>                      
                      <!-- <template slot="append">.gsh56.com</template> -->
                    </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <span
                      style="
                        margin-left: 10px;
                        text-algin: center;
                        line-height: 30px;
                      "
                      >域名在订阅成功后生效！</span
                    >
                  </el-col>
                </el-row>
              </template>
            </box>
          </el-col>
        </el-row>

        <el-row>
          <el-col :offset="4" :span="16">
            <box class="box_view_bottom" title="订阅信息">
              <template v-slot:body>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="产品信息">
                      <span style="margin-right: 10px">{{
                        subscribeDto.proSubInfo.spName
                      }}</span>
                      <el-tag
                        size="small"
                        effect="dark"
                        hit="false"
                        :color="checkTagColor(subscribeDto.proSubInfo.typeName)"
                        v-if="subscribeDto.proSubInfo.typeName != null"
                        >{{ subscribeDto.proSubInfo.typeName }}</el-tag
                      >
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="订阅价格">
                      <span style="color: #ff502e; margin-right: 10px">{{
                        subscribeDto.proSubInfo.unitPrice
                      }}</span
                      ><span>{{ subscribeDto.proSubInfo.unitName }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="产品简介">
                      <span>{{ subscribeDto.proSubInfo.spRemark }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row v-if="stepActive == 3 || stepActive == 4">
                  <el-col :span="24">
                    <el-form-item label="试用有效期">
                      <span
                        >{{ subscribeDto.proSubInfo.startTime }} 到
                        {{ subscribeDto.proSubInfo.endTime }}
                      </span>
                      <span
                        >{{ subscribeDto.proSubInfo.overTimTitle }}
                        {{ subscribeDto.proSubInfo.overTimes }} 天</span
                      >
                      <el-button size="medium" type="text" v-if="stepActive == 4" @click="gotoSubViewAction()"
                        >继续续费订阅</el-button
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </box>
          </el-col>
        </el-row>

        <el-row>
          <el-col :offset="4" :span="16">
            <div class="bottom_view" v-if="stepActive == 4">
              <el-button
                icon="el-icon-edit"
                type="primary"
                size="medium"
                @click="gotoMySysAction()"
                >前往系统试用
              </el-button>
            </div>
            <div class="bottom_view" v-else-if="stepActive == 1">
              <el-button :disabled="true" size="medium"
                >平台审核中，请稍等
              </el-button>
            </div>
            <div class="bottom_view" v-else-if="stepActive == 2">
              <el-button :disabled="true" size="medium"
                >平台部署中，请稍等
              </el-button>
            </div>
            <div class="bottom_view" v-else>
              <el-button size="medium" @click="resetAction">重 置 </el-button>
              <el-button
                type="primary"
                size="medium"
                :loading="gotoPayLoding"
                @click="gotoPayAction"
                >提交申请
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { gridMixin } from "@/mixins/grid-mixins";
import { mapGetters } from "vuex";
import aEntityAPI from "@/api/sysAPI.js";
import { formatDate, dateAdd, getMonthByTwoTimes } from "@/utils/utils.js";
export default {
  mixins: [gridMixin],
  props: {
    id: {
      type: [String, Number],
    },
    subId: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "user/userinfo",
      shipper: "user/shipper",
    }),
  },

  watch:{
    $route(to, from) {
      let that = this;
      if (to.path.indexOf("saasSubscribeTab") > -1) {
        if (to.params.stepActive > 0) {
            that.getSubscribeDetail();
        }
      }
    },
  },

  data() {
    return {
      gotoPayLoding: false,
      curId:null,
      curSubId:null,
      tenantDomain: "", //基础Saas管理的tenant的所属domain
      stepActive: 0,
      subscribeDto: {
        tenantInfo: {},
        proSubInfo: {},
      },
    };
  },
  methods: {
    //背景色
    checkTagColor(typeName) {
      // 工具(1), 资源(2),数据(3),服务(4);
      if (typeName == "工具") {
        return "#409EFF"; //蓝色
      } else if (typeName == "资源") {
        return "#E6A23C"; //黄色
      } else if (typeName == "数据") {
        return "#F56C6C"; //红色
      } else {
        return "#67C23A"; //绿色
      }
    },

    //进入我的系统
    gotoMySysAction() {
      var urls = this.subscribeDto.tenantInfo.domain;
      if(urls != null && urls != ""){
        var urlList = urls.split(",");
        var host = urlList[0];
       host = window.location.protocol + "//" + host + "/login.html#/login";
        window.open(host);
      }else{
        this.$message({
              showClose: true,
              message: "域名为空",
              type: "warning",
            });
      }
    },

    //重置
    resetAction() {
      this.subscribeDto.tenantInfo = {};
    },

    gotoSubViewAction(){
      //前往续费订阅
      this.$emit('goToSubscribeAction', {});

    },

    //提交申请
    gotoPayAction() {
      let that = this;
      that.$refs.dataDictRef.validate((valid) => {
        if (valid) {
          that.gotoPayLoding = true;
          aEntityAPI
            .saasRequest("UserProductSubscribeService", "saasExpSubscribeApply", {
              tentInfo: JSON.stringify(this.subscribeDto.tenantInfo),
              saasProId: this.curId,
              "@TenantDomain": this.tenantDomain
            })
            .then((res) => {
              that.gotoPayLoding = false;
              if (res.data.success) {
                that.stepActive = 1;
                that.getSubscribeDetail();
                this.$message({
                  showClose: true,
                  message: "提交申请订阅成功",
                  type: "success",
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.msg ? res.data.msg : "提交申请订阅失败",
                  type: "warning",
                });
              }
            })
            .catch((res) => {
              this.gotoPayLoding = false;
            });
        }
      });
    },

    //获取订阅产品的明细信息
    getSubscribeDetail() {
      let that = this;
      aEntityAPI
        .saasRequest("UserProductSubscribeService", "getExpSubscribeInfo", {
          proId: this.curId,
          subId: this.curSubId,
          "@TenantDomain": this.tenantDomain
        })
        .then((res) => {
          if (res.data.success) {
            var dictT = res.data.data;
            if (dictT.tenantInfo != null) {
              that.subscribeDto.tenantInfo = dictT.tenantInfo;
            }
            if (dictT.proInfo != null) {
              that.subscribeDto.proSubInfo = dictT.proInfo;
              if (
                dictT.proInfo.endNowNum != null &&
                dictT.proInfo.endNowNum < 0
              ) {
                that.subscribeDto.overTimTitle = "已过期";
                that.subscribeDto.overTims = -dictT.proInfo.endNowNum;
              } else {
                that.subscribeDto.overTimTitle = "剩余";
                that.subscribeDto.overTims = dictT.proInfo.endNowNum;
              }
              if (dictT.proInfo.spsStatusValue == 7) {
                that.stepActive = 4;
              } else if (dictT.proInfo.spsStatusValue == 5) {
                that.stepActive = 2;
              }
              else if (dictT.proInfo.spsStatusValue == 0) {
                that.stepActive = 1;
                that.$emit('changeTabIndexShow', {});
              } else {
                that.stepActive = 0;
              }
            }
            
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg ? res.data.msg : "查询产品订阅数据失败",
              type: "warning",
            });
          }
        })
        .catch((res) => {});
    },

    
  },
 // 挂载查询
  beforeMount: function () {
    
  },

  mounted() {
    if (this.$route.params && this.$route.params.id != null) {
      this.curId = this.$route.params.id;
    }
    if (this.$route.params && this.$route.params.subId != null) {
      this.curSubId = this.$route.params.subId;
    }
    aEntityAPI
        .saasRequest("MasterTenantService", "getSaasAdminSysTenantDomain", {
          "@TenantDomain": this.tenantDomain
        })
        .then((res) => {
          this.grid_loading = false;
          if (res.data.success) {
            this.tenantDomain = res.data.data.tenantDomain.split(',')[0];
            this.getSubscribeDetail();
          }
        })
  
  },

  destroyed() {},
};
</script>

<style>
.zq_saas_subscribe .top_view {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
  margin-bottom: 40px;
}

.zq_saas_subscribe .body_view {
  background-color: #fff;
}

.zq_saas_subscribe .title_step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.zq_saas_subscribe .title_step_lab {
  font-size: 20px;
  color: black;
}

.zq_saas_subscribe .bottom_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 60px;
}


.zq_saas_subscribe .box_view_top{
    border: 1px solid #ddd;
    border-bottom: 1px solid #fff;
}

.zq_saas_subscribe .box_view_bottom{
    border: 1px solid #ddd;
    border-top: 1px solid #fff;
}

.zq_saas_subscribe .amt_color {
  color: #ff502e;
}
</style>
