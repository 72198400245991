<template>
    <div class="page-list unset-min-width">

        <div class="page-list-content">
            <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container"
                @wheel.native.prevent="handleScroll">
                <span v-for="(item,index) in pagelist" :key="item.path"
                    :class="['page-list-item' ,{'active': item.open}]" @click="openPage(item)"
                    @contextmenu="showMenu(index)">
                    <a class="page-list-a">{{item.title}}</a>
                    <i v-if="item.closeable" class="el-icon-close" @click.stop="closeSelf(item)"></i>
                    <div class="page-seperate"></div>
                    <context-menu :context-menu-data="contextMenuData" :transfer-index="transferIndex"
                        :menulist="item | getMenuList" @close-self="closeSelf(item)" @close-all="closeAll"
                        @close-oth="closeOth(item)"></context-menu>
                </span>
            </el-scrollbar>
            
        </div>

    </div>
</template>
<script>
    import {
        mapActions,
        mapGetters
    } from 'vuex';
    import ContextMenu from '@/components/ContextMenu.vue'
    //import {getTransformXValue} from '../utils/utils'
    export default {
        components: {
            ContextMenu
        },
        computed: {
            ...mapGetters({
                pagelist: 'page/pagelist',
            }),
            scrollWrapper() {
                return this.$refs.scrollContainer.$refs.wrap
            }
        },
        data() {
            return {
                transferIndex: null,
                contextMenuData: {
                    menuName: 'tabs',
                    axios: {
                        x: null,
                        y: null
                    }
                }
            }
        },
        filters: {
            getMenuList: function (value) {
                if (value.closeable) {
                    return [{
                            handler: 'close-self',
                            name: '关闭当前页'
                        },
                        {
                            handler: 'close-all',
                            name: '关闭所有页面'
                        }, {
                            handler: 'close-oth',
                            name: '关闭其他页面'
                        }
                    ]
                } else {
                    return [{
                        handler: 'close-all',
                        name: '关闭所有页面'
                    }, {
                        handler: 'close-oth',
                        name: '关闭其他页面'
                    }]
                }
            }
        },
        methods: {
            ...mapActions([
                "page/closePage",
                "page/clearPage",
                "page/closeOtaPage"
            ]),

            showMenu(index) {
                console.log(index)
                this.transferIndex = index // tranfer index to child component
                event.preventDefault()
                // let offet_x = getTransformXValue(document.querySelector(".page-list-ul")) //偏移量
                var x = event.clientX
                var y = event.clientY
                this.contextMenuData.axios = {
                    x,
                    y
                }
            },
            openPage(item) {
                console.log(item)
                if (this.$route.path != item.path) {
                    this.$router.push({
                        name: item.name,
                        params: item.params,
                        query: item.query
                    })
                }
            },
            closeSelf(item) {
                this['page/closePage']({
                    ctx: this,
                    pageM: item
                });
            },
            closeOth(item) {
                this['page/closeOtaPage']({
                    ctx: this,
                    pageM: item
                });
            },
            closeAll() {
                this['page/clearPage']({
                    ctx: this
                });
            },

          
            handleScroll(e) {
                const eventDelta = e.wheelDelta || -e.deltaY * 40
                const $scrollWrapper = this.scrollWrapper
                $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
            }
        },

        mounted() {
            
        },
        
    }
</script>