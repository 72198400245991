import * as types from '../mutation-types'

const state = {
    msg: Object
}


const getters = {
    msg: state => state.msg
}

const actions = {
    sendMsg({ commit }, msg) {
        commit(types.MSG_SEND, msg)
    },
    readMsg({ commit }, msg){
        commit(types.MSG_READ, msg)
    }
}

const mutations = {
    [types.MSG_SEND](state, msg) {
        state.msg = msg
    },
    [types.MSG_READ](state, msgId) {
        delete state.msg[msgId]
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}