<template>

  <el-dialog title="导出文件" :visible.sync="tradeDialogVisible" @opened="handleOpenExport" width="90%"
             @closed="handleDialogClose" ref="export" append-to-body>

    <div style="float: left">
      <el-button type="primary" @click="cleanExport()">清除提示记录</el-button>
    </div>
    <div style="float: right">
      <el-input style="width:150px" placeholder="姓名/手机号" v-model="searchForm.username"
                clearable></el-input>
      <enum-selector
          v-model="searchForm.taskExportType"
          clazz="AsyncExportTypeE"
          class="inline"
          style="width: 150px;"
      ></enum-selector>
      <el-date-picker
          v-model="searchForm.startAndEndTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          value-format="yyyy-MM-dd"
          style="width: 220px"
          end-placeholder="结束日期">
      </el-date-picker>
      <el-button icon="el-icon-refresh-left" size="mini"
                 @click="reset">重置
      </el-button>
      <el-button type="primary" size="mini" icon="el-icon-search" @click="search()">搜索</el-button>
    </div>

    <el-table :data="listData" border style="width: 100%" max-height="550"  v-loading="grid_loading">
      <el-table-column type="selection" fixed="left" width="60" align="center"
                       reserve-selection></el-table-column>
      <el-table-column prop="taskExportType" label="标题" width="150" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.read == 0 " style="font-weight: bolder">{{ scope.row.taskExportType }}</div>
          <div v-else>{{ scope.row.taskExportType }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="导出进度" width="170" align="center">
        <template slot-scope="scope">
          <div>已处理 {{ scope.row.handleCount }}条 / 总 {{ scope.row.totalCount }}条</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="120" align="center">
        <template slot-scope="scope">
          <el-tag size="medium" type="success"
                  v-if="scope.row.status == '文档生成完成'">
            {{ scope.row.status }}
          </el-tag>
          <el-tag size="medium" type="warning"
                  v-if="scope.row.status == '文档生成中'">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="执行导出" width="160" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.username }}</div>
          <div>{{ scope.row.createTime }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="times" label="耗时" width="80" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.times + "秒" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="downloadTime" label="下载记录" width="200" align="center">
        <template slot-scope="scope" v-if="scope.row.downloadCount">
          <div>最近下载 : {{ scope.row.username }}</div>
          <div>{{ scope.row.downloadTime }}
            <span>共下载{{ scope.row.downloadCount }}次</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="downloadTime" label="是否阅读" width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.read == 1" type="success">
            已读
          </el-tag>
          <el-tag v-else type="warning">
            未读
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column></el-table-column>
      <el-table-column label="操作" width="50" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="downloadExcel(scope.row)" :disabled="scope.row.status =='文档生成中'">下载
          </el-button>
          <!-- <el-button type="text" @click="deleteExcel(scope.row)"
                     :disabled="scope.row.status !='文档生成中'">取消
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <div style="float: right;">

      <!--      <gh-order-batch ref="ghOrderBatch" class="gh-batch-order-solution-bar" :select-order="selectedRow"-->
      <!--                      @refreshOrd="handleRefreshOrd">-->
      <!--      </gh-order-batch>-->

      <el-pagination background layout="sizes, prev, pager, next, total" :total="grid_total"
                     :page-size.sync="grid_pageSize" @current-change="pageChange"
                     :current-page.sync="grid_pageNo">
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
    </div>
  </el-dialog>

</template>
<script>
import {gridMixin} from "@/mixins/grid-mixins";
import aEntityAPI from "@/api/aEntityAPI.js";
import {roleMixin} from "../../mixins/role-mixins.js";
import {_util} from "@/utils/pmss";
import {mapGetters} from "vuex";
import XlxsUploader from "@/components/uploader/xlxs-uploader.vue";
import whEntityAPI from "@/project/wms/api/wmsEntityApi";

export default {
  mixins: [gridMixin, roleMixin],
  components: {
    XlxsUploader,
  },
  computed: {
    ...mapGetters({
      shipper: "user/shipper",
      team: "user/team",
    }),
    //按钮：订单竞标
    btnBidRight() {
      if (_util.hasPermission('/bidOrderList', null) == false) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      tradeDialogVisible: false,
      copyIndex: 0,
      unAcceptOrderNum: 0,
      importUrl: process.env.VUE_APP_BACKEND_URL_PROXY + "/api/OmsOrderService/importXls",
      publicPath: process.env.VUE_APP_BASE_URL,
      listData: [], //列表数据
      cls: "OmsOrder",
      isPadding: false,
      dialogFormVisible: false,
      searchForm: {
        //查询表单
        vhc__eq: "",
        createTime__ge: "",
        createTime__le: "",
        type__eq: "",
        source__eq: "",
        startTime: "",
        type: "",
        main: "",
        endTime: "",
        project: {},
        shipper: {},
      },
      selectedRow: [], //已选的行
      assignCarrier: null,
      orderAssignVisible: false,
      orderAssignServer: false,
      sureVisible: false,
      submitting: false,
      OrderBids: {
        type1: null,
        type2: null,
        type3: null,
        issueType: 0,
      },
      rules: {
        type1: [
          {required: true, message: "请选择竞标方式", trigger: "change"},
        ],
        type2: [
          {required: true, message: "请选择报价方式", trigger: "change"},
        ],
      },
      remarkVisible: false,
      batchRemark: ""
    };
  },
  methods: {

    show() {
      this.tradeDialogVisible = true;
    },

    handleOpenExport() {
      this.search();
    },
    handleDialogClose() {
      // this.search();
    },
    cleanExport() {
      aEntityAPI.request("AsyncTaskService", "cleanExport", {
        type:0
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("导出提示数据清除成功");
          this.search();
          this.$parent.$parent.queryTaskCount();
        } else {
        }
      })
    },
    getCarrierStyle(company_status) {
      if (company_status == '待确认') {
        return 'warning';
      }
      if (company_status == '撤销') {
        return 'danger';
      }
      return 'success';
    },
    // countUnAcceptOrderNum() {
    //   aEntityAPI
    //       .request("OrderAssignService", "countUnAcceptOrder")
    //       .then((response) => {
    //         if (response.data.success) {
    //           this.unAcceptOrderNum = response.data.data;
    //         }
    //       });
    // },
    copyOrder(item) {
      let index = this.copyIndex + 1;
      this.$router.push({
        name: "copyOrder",
        params: {
          copyIndex: index,
          orderId: item.id,
          no: item.no,
          shipperId: item.shipperId,
        },
        query: {
          copyIndex: index,
          orderId: item.id,
          no: item.no,
          shipperId: item.shipperId,
        },
      });
    },
    filterStatus(statusCode) {
      var statusText = "info";
      switch (statusCode) {
        case -1:
          statusText = "danger";
          break;
        case 1:
          statusText = "info";
          break;
        case 4:
          statusText = "";
          break;
        case 7:
          statusText = "";
          break;
        default:
          statusText = "success";
          break;
      }
      return statusText;
    },
    add() {
      this.$router.push({
        name: "newOrder",
        params: {},
        query: {},
      });
    },

    getSearchField() {
      return {
        type: 0,
        username: this.searchForm.username,
        taskExportType: this.searchForm.taskExportType ? this.searchForm.taskExportType.value : null,
        startTime: this.searchForm.startAndEndTime ? this.searchForm.startAndEndTime[0] + " 00:00:00" : null,
        endTime: this.searchForm.startAndEndTime ? this.searchForm.startAndEndTime[1] + " 23:59:59" : null,
      };
    },
    //当前日期向后推迟一天
    nextEndTime(current) {
      if (current == "") return current;
      var dateTime = new Date(current);
      dateTime.setDate(dateTime.getDate() + 1);
      let now = new Date(dateTime);
      return this.formatDate("yyyy-MM-dd hh:mm:ss", now);
    },
    formatResult(item) {
      return item.plate;
    },
    formatSelection(item) {
      //不是必须 默认以name 显示 去报 服务端返回 包含name字段
      return item.plate;
    },
    getParam(start, query) {
      const params = {
        start: start,
        limit: 10,
        cls: "com.gsh56.wlhy.model.bs.Vhc",
        fields: ["id", "plate"],
        q: {
          plate__like: query,
        },
      };

      return {q: JSON.stringify(params)};
    },

    search() {
      var _this = this;
      let params = {
        start: this.grid_pageSize * (this.grid_pageNo - 1),
        limit: this.grid_pageSize,
        cls: "AsyncTask",
        fields: ["id", "name", "count", "status"],
        sort: "id__desc",
        q: _this.getSearchField(),
      };
      this.grid_loading = true;
      aEntityAPI.request("AsyncTaskService", "queryAsyncTaskPage", {qp: JSON.stringify(params)}).then((res) => {
        this.grid_loading = false;
        if (res.data.success) {
          this.listData = res.data.data;
          this.grid_total = res.data.total;
        } else {
          this.$message.error(res.data ? res.data.msg : "查询列表失败",)
        }
      }).catch(_ => {
      })
    },

    downloadExcel(row) {
      aEntityAPI.request("AsyncTaskService", "updateAsyncTask", {id: row.id}).then((res) => {
        if (res.data.success) {
          // var url = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BACKEND_URL_PROXY + "/file/download?fileGuid=" + row.fileGuid
          // window.open(url);
          this.download(row.filePath,row.file_name,row.extension)
          this.$parent.$parent.queryTaskCount();
        }
      });
    },

    download(filePath, fileName, extension) {
      const file = {
        filePath,
        fileName,
        extension,
      };
      aEntityAPI.downloadFilePath(file);
    },

    deleteExcel(row) {
      aEntityAPI.request("AsyncTaskService", "deleteByTaskId", {id: row.id}).then((res) => {
        if (res.data.success && res.data.data != null) {
          this.$message.success('删除成功');
        }
      });
    },

    pageChange() {
      this.search();
    },
    handleSelect(selection) {
      this.selectedRow = selection;
    },

    validateRowSelected() {
      if (this.selectedRow.length <= 0) {
        this.$message({
          showClose: true,
          message: "请选择数据",
          type: "warning",
        });
        return;
      }
    },

    reset(a) {
      this.searchForm = {};
      this.$refs.orderOperateTable.clearSelection();
    },
    moreSearchConfirm() {
      this.dialogFormVisible = false;
      this.search();
    },
    //
    getCarrierParam(start, query) {
      const params = {
        start: start,
        limit: 10,
        cls: "Role",
        fields: ["id", "name", "code"],
        q: {
          name__like: query,
          lineFlag: true,
        },
      };

      return {qp: JSON.stringify(params)};
    },

    accept() {
      this.$router.push({
        name: "orderAccept",
      });
    },
    __refresh() {
      //用于自动刷新
      this.search();
    },
    handleRefreshOrd(flag) {
      if (flag) {
        this.search();
        this.$refs.orderOperateTable.clearSelection();
      }
    },
    batchModifyRemark() {
      this.remarkVisible = true;
    },
  },
  mounted() {
    // this.countUnAcceptOrderNum();
    this.search();
  },
  watch: {
    $route(to, from) {
      if (to.name == "orderOperate") {
        // this.countUnAcceptOrderNum();
      }
    },
  },
};
</script>
<!--<style>
.carrier-desc {
  color: #f56c6c;
  font-weight: bold;
}

.gh-batch-order-solution-bar {
  display: inline-block;
  width: 550px;
  padding: 2px;
}

.el-dropdown-lrmg {
  margin-left: 10px;
  margin-right: 10px;
}

.el-select {
  width: 100%;
}

.el-input&#45;&#45;mini .el-input__inner {
  padding-left: 4px;
}

.el-input__inner::placeholder {
  color: #dddee1;
}
</style>-->
